import React from 'react';
import { ThreeDots } from 'react-loader-spinner';

import './LoaderData.scss';

const LoaderData = () => (
  <ThreeDots
    height="80"
    width="80"
    radius="9"
    color="#E65634"
    ariaLabel="three-dots-loading"
    wrapperStyle={{}}
    wrapperClassName=""
    visible={true}
    wrapperClass={'three-dots-loading'}
  />
);

export default LoaderData;
