import React from 'react';
import './OrangeButton.scss';

const OrangeButton = ({ children, onClick = null, className }) => (
  <button className={`orange-button ${className}`} onClick={onClick}>
    {children}
  </button>
);

export default OrangeButton;
