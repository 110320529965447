import React, { useState, useEffect, useContext } from 'react';
import Switcher from '../../components/Switcher/Switcher';
import OrangeBorderButton from '../../components/OrangeBorderButton/OrangeBorderButton';
import PoolItem from '../../components/PoolItem/PoolItem';
import {
  getBuyPoolsByCollections,
  getOfferPoolsByCollections,
  getSellPoolsByCollections,
  getTradePoolsByCollections,
} from '../../services/wax.services';
import { UALContext } from 'ual-reactjs-renderer';
import FillterSideBar from '../../components/FilterSideBar/FilterSideBar';
import LoaderData from '../../components/LoaderData/LoaderData';

import './Pool.scss';

const Pool = () => {
  const { activeUser } = useContext(UALContext);
  const [checked, setChecked] = useState(true);
  const [sellPools, setSellPools] = useState([]);
  const [buyPools, setBuyPools] = useState([]);
  const [tradePools, setTradePools] = useState([]);
  const [offerPools, setOfferPools] = useState([]);
  const [searchCollection, setSearchCollection] = useState('');
  const [selectedFilterCollection, setSelectedFilterCollection] = useState('all');
  const [selectedFilterSchema, setSelectedFilterSchema] = useState([]);
  const [selectedPoolType, setSelectedPoolType] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPools = async () => {
      try {
        const [sellPoolsData, buyPoolsData, tradePoolsData, offerPoolsData] = await Promise.all([
          getSellPoolsByCollections(),
          getBuyPoolsByCollections(),
          getTradePoolsByCollections(),
          getOfferPoolsByCollections(),
        ]);
        
        const filteredSellPools = filterPools(sellPoolsData);
        const filteredBuyPools = filterPools(buyPoolsData);
        const filteredTradePools = filterPools(tradePoolsData);
        const filteredOfferPools = filterPools(offerPoolsData);

        setSellPools(filteredSellPools);
        setBuyPools(filteredBuyPools);
        setTradePools(filteredTradePools);
        setOfferPools(filteredOfferPools);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    const filterPools = (pools) => {
      let res = pools.filter(
        (pool) =>
          pool.pool_owner === activeUser?.accountName &&
          pool.token_contract === 'eosio.token'
      );
        
      return res;
    };

   
    fetchPools();
  }, [activeUser]);

  const filteredPools = (pools, type) => {
    if (selectedPoolType === type) {
      return pools;
    } else if (selectedPoolType === 'nft' || selectedPoolType === 'token') {
      return [];
    } else {
      return pools;
    }
  };

  return (
    <div className={'poole-section'}>
      <div className="poole-section-title">
        <h2>Pools Overview</h2>
        <p>
          View all of the pools that {activeUser?.accountName} provides liquidity for
        </p>
        <Switcher
          checked={checked}
          setChecked={setChecked}
          label={checked ? 'Hiding empty pool' : 'Showing empty pools'}
        />
        <OrangeBorderButton
          classNameForMain={'poole-section-title_create'}
          width={179}
          height={44}
          link={'/create'}
        >
          + Create New Pool
        </OrangeBorderButton>
      </div>

      {loading ? (
        <div className={'loader-data'}>
          <LoaderData />
        </div>
      ) : (
        <div className="poole-section-content">
          <FillterSideBar
            sellPools={sellPools}
            buyPools={buyPools}
            checked={checked}
            setChecked={setChecked}
            setSearchCollection={setSearchCollection}
            selectedFilterCollection={selectedFilterCollection}
            setSelectedFilterCollection={setSelectedFilterCollection}
            setSelectedFilterSchema={setSelectedFilterSchema}
            selectedPoolType={selectedPoolType}
            setSelectedPoolType={setSelectedPoolType}
          />
          <div className="poll-list">
            <PoolItem
              filteredSellPools={filteredPools(sellPools, 'nft')}
              filteredBuyPools={filteredPools(buyPools, 'token')}
              filteredTradePools={filteredPools(tradePools, 'trade')}
              filteredOfferPools={filteredPools(offerPools, 'offer')}
              checked={checked}
              searchCollection={searchCollection}
              selectedFilterCollection={selectedFilterCollection}
              selectedFilterSchema={selectedFilterSchema}
              selectedPoolType={selectedPoolType}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Pool;
