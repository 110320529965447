import React, { useState, useEffect, useContext } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import {
  BuyNfts,
  getBuyPoolsByCollections,
  getFeesConfig,
  getMainConfig,
  getMyNFTs,
  getNftsByIds,
  getSellPoolsByCollections,
  getTradePoolsByCollections,
  sendAssets,
} from '../../services/wax.services';
import { UALContext } from 'ual-reactjs-renderer';
import { EOSIO_TOKEN } from '../../constants/wax.constants';

import OrangeBorderButton from '../../components/OrangeBorderButton/OrangeBorderButton';

import './SwapPage.scss';
import { toast } from 'react-toastify';
import LoaderData from '../../components/LoaderData/LoaderData';
import { getCurrentToken, shortenText } from '../../helpers';
import { toastOptions } from '../../helpers/toast.helper';

const SwapPage = () => {
  const { name, id } = useParams();
  const navigate = useNavigate();
  const { activeUser } = useContext(UALContext);
  const location = useLocation();
  const [currentLocation, setCurrentLocation] = useState('');

  useEffect(() => {
    setCurrentLocation(location.pathname.split('/')[4]);
  }, [location]);

  const [currentPool, setCurrentPool] = useState(null);
  const [myNFTs, setMyNFTs] = useState([]);
  const [filteredNFTs, setFilteredNFTs] = useState([]);
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [mainConfig, setMainConfig] = useState([]);
  const [feesConfig, setFeesConfig] = useState(0);

  const [totalCostWithFees, setTotalCostWithFees] = useState(0);
  const [totalCostWithOutFees, setTotalCostWithOutFees] = useState(0);
  const [tokenAmount, setTokenAmount] = useState(0);

  const [delta, setDelta] = useState(0);

  const [NFTonSale, setNFTonSale] = useState([]);
  const [currentDelta, setCurrentDelta] = useState('Linear');

  const [tradePoolSwitch, setTradePoolSwitch] = useState('Buy');

  useEffect(() => {
    let price = Number(currentPool?.floor_price?.split(' ')[0]);
    const tokenRatio = Number(mainConfig?.token_ratio);
    let tokenAmount = 0;

    if (selectedAssets.length === 0 || !price || !tokenRatio) {
      setTokenAmount(0);
      return;
    }

    for (let i = 0; i < selectedAssets.length; i++) {
      const totalToken = price * 0.99;
      const fee = price - totalToken;
      tokenAmount += fee;
      price -= 0.1;
    }

    setTokenAmount(tokenAmount * tokenRatio);
  }, [selectedAssets, currentPool, mainConfig]);


  useEffect(() => {
    let isMounted = true;
    const newOffset = 6 - 6;
    const newLimit = 6;

    const currPoolNFTs = currentPool?.pool_nfts;

    if (currPoolNFTs) {
      getNftsByIds(currPoolNFTs, newOffset, newLimit)
        .then((data) => {
          if (isMounted) {
            if (data.length === 0) {
              // setAllElementsLoaded(true);
            } else {
              setNFTonSale(data);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    return () => {
      isMounted = false;
    };
  }, [currentPool]);

  useEffect(() => {
    if (currentPool && myNFTs) {
      const filteredNfts = myNFTs.filter(item => {
        const hasValidSchema = currentPool.schemas.length === 0 ||
          currentPool.schemas.includes(item.schema.schema_name);
  
        const hasValidTemplate = currentPool.templates.length === 0 ||
          currentPool.templates.includes(item.template.template_id);
  
        return hasValidSchema && hasValidTemplate;
      });
  
      setFilteredNFTs(filteredNfts);
    } else {
      setFilteredNFTs(myNFTs);
    }
  }, [myNFTs, currentPool]);
  

  useEffect(() => {
    if (!currentPool) return;
    if (currentPool.linear_delta > 0) {
      setDelta(currentPool.linear_delta / Math.pow(10, 8));
      setCurrentDelta('Linear');
    } else if (currentPool.exponential_delta !== '0.00000000000000000') {
      setDelta(parseFloat(currentPool.exponential_delta));
      setCurrentDelta('Exponential');
    } else {
      setDelta(0);
      setCurrentDelta('Linear');
    }
  }, [currentPool]);

  useEffect(() => {
    const fetchPool = async () => {
      try {
        let buyPools;
        if (currentLocation === 'sell')
          buyPools = await getSellPoolsByCollections();

        if (currentLocation === 'buy')
          buyPools = await getBuyPoolsByCollections();

        if (currentLocation === 'trade')
          buyPools = await getTradePoolsByCollections();

        const pool = buyPools.find((item) => item.pool_id === parseInt(id));
        setCurrentPool(pool);
      } catch (error) {
        console.log(error);
      }
    };

    fetchPool();
  }, [id, currentLocation]);

  useEffect(() => {
    getMyNFTs(activeUser?.accountName, name)
      .then((data) => {
        setMyNFTs(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [activeUser, name]);

  useEffect(() => {
    getMainConfig()
      .then((data) => {
        setMainConfig(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    getFeesConfig()
      .then((data) => {
        const filteredData = data.filter(
          (item) => item.token_contract === EOSIO_TOKEN
        );
        setFeesConfig(filteredData[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const price = Number(currentPool?.floor_price?.split(' ')[0]);
    const feePercentage = Number(feesConfig?.fees?.[0]?.value) || 0;
    let totalCost = 0;

    if (selectedAssets.length === 0) {
      setTotalCostWithFees(0);
    } else {
      for (let i = 0; i < selectedAssets.length; i++) {
        let assetPrice;
        if (currentDelta === 'Linear') {
          assetPrice = i === 0 ? price : price - delta * i;
        } else if (currentDelta === 'Exponential') {
          assetPrice = price * (1 - delta / 100) ** i;
        }
        const assetCost = assetPrice * (1 - feePercentage / 100);
        totalCost += assetCost;
      }
      setTotalCostWithFees(totalCost);
    }
  }, [selectedAssets, currentPool, feesConfig, currentDelta, delta]);

  useEffect(() => {
    const price = Number(currentPool?.floor_price?.split(' ')[0]);
    let totalCost = 0;

    if (selectedAssets.length === 0) {
      setTotalCostWithOutFees(0);
    } else {
      for (let i = 0; i < selectedAssets.length; i++) {
        let assetPrice;
        if (currentDelta === 'Linear') {
          assetPrice = i === 0 ? price : price + delta * i;
        } else if (currentDelta === 'Exponential') {
          assetPrice = price * (1 + delta / 100) ** i;
        }
        totalCost += assetPrice;
      }
      setTotalCostWithOutFees(totalCost);
    }
  }, [selectedAssets, currentPool, currentDelta, delta]);

  const handleSwapToBuyPool = () => {
    sendAssets(activeUser, selectedAssets, id, currentLocation)
      .then(() => {
        toast.success('Success', toastOptions);

        setTimeout(() => {
          goBack();
        }, 1500);
      })
      .catch((error) => {
        const errorMessage = error.message.replace(
          'assertion failure with message: ',
          ''
        );
        toast.error(errorMessage, toastOptions);
        console.log(error);
      });
  };

  const handleSwapToSellPool = () => {
    BuyNfts(
      activeUser,
      id,
      totalCostWithOutFees,
      currentPool?.token_contract === EOSIO_TOKEN ? '8,WAX' : '4,WUF',
      selectedAssets,
      currentLocation,
      currentPool?.token_contract
    )
      .then(() => {
        toast.success('Success', {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });

        setTimeout(() => {
          goBack();
        }, 1500);
      })
      .catch((error) => {
        const errorMessage = error.message.replace(
          'assertion failure with message: ',
          ''
        );
        toast.error(errorMessage, {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        console.log(error);
      });
  };

  useEffect(() => {
    setSelectedAssets([]);
  }, [tradePoolSwitch]);


  const addAsset = (item) => {
    if (!item) {
      return; // Return early if item is undefined or null
    }

    let poolAsset = Number(currentPool?.pool_asset?.split(' ')[0]) || 10000;
    const floorPrice = Number(currentPool?.floor_price?.split(' ')[0]);

    const canToSell = Math.floor(poolAsset / floorPrice);

    if (currentLocation === 'buy' || currentLocation === 'sell') {
      if (poolAsset >= floorPrice && selectedAssets.length < canToSell) {
        if (selectedAssets.includes(item.asset_id)) {
          setSelectedAssets(
            selectedAssets.filter((id) => id !== item.asset_id)
          );
          poolAsset = (poolAsset + floorPrice).toFixed(4);
        } else {
          setSelectedAssets(selectedAssets.concat(item.asset_id));
          poolAsset = (poolAsset - floorPrice).toFixed(4);
        }
      } else if (selectedAssets.includes(item.asset_id)) {
        setSelectedAssets(selectedAssets.filter((id) => id !== item.asset_id));
        poolAsset = (poolAsset + floorPrice).toFixed(4);
      }
    } else if (currentLocation === 'trade') {
      if (
        tradePoolSwitch === 'Sell' &&
        !selectedAssets.includes(item.asset_id)
      ) {
        setSelectedAssets([...selectedAssets, item.asset_id]);
      } else if (
        tradePoolSwitch === 'Buy' &&
        !selectedAssets.includes(item.asset_id)
      ) {
        if (selectedAssets.length < canToSell) {
          setSelectedAssets([...selectedAssets, item.asset_id]);
        }
      } else {
        setSelectedAssets(selectedAssets.filter((id) => id !== item.asset_id));
      }
    }
  };

  function goBack() {
    navigate(-1);
  }

  return (
    <div className="swap-page">
      <div className="swap-page_title">
        <h2>Swap</h2>
      </div>

      {currentLocation === 'trade' && (
        <div className="pool-switcher">
          <OrangeBorderButton
            className={tradePoolSwitch === 'Buy' ? 'active' : ''}
            onClick={() => setTradePoolSwitch('Buy')}
          >
            Buy pool
          </OrangeBorderButton>
          <OrangeBorderButton
            className={tradePoolSwitch === 'Sell' ? 'active' : ''}
            onClick={() => setTradePoolSwitch('Sell')}
          >
            Sell pool
          </OrangeBorderButton>
        </div>
      )}

      <div className="pool-block">
        <div className="pool-block_wrapper">
          <div className="item-type">
            {currentPool && <div className="item-type-left">{getCurrentToken(currentPool.token_contract)} - {name}</div>}
            {currentLocation === 'trade' && tradePoolSwitch === 'Buy' && (
              <div className="item-type-right">BUY</div>
            )}

            {currentLocation === 'trade' && tradePoolSwitch === 'Sell' && (
              <div className="item-type-right">SELL</div>
            )}

            {currentLocation === 'buy' && (
              <div className="item-type-right">BUY</div>
            )}

            {currentLocation === 'sell' && (
              <div className="item-type-right">SELL</div>
            )}
          </div>
          <div className="container">
            <div className="container-wrapper">
              {currentLocation !== 'trade' ? (
                <div className="price">
                  <div className="price_left">
                    <p className={'little-title'}>Buy Price</p>
                    {currentPool &&
                      <div className="price-amount">
                      <p>{currentPool?.floor_price}</p>
                    </div>
                    }
                  </div>
                  <div className="price-right">
                    <p className={'price-right-name'}></p>
                    {/*<p className="price-right-amount">-</p>*/}
                  </div>
                </div>
              ) : (
                <div className="price">
                  {tradePoolSwitch === 'Buy' && (
                    <div className="price_left">
                      <p className={'little-title'}>Sell Price</p>
                      {currentPool &&
                       <div className="price-amount">
                       <p>{currentPool?.floor_price}</p>
                      </div>
                      }
                    </div>
                  )}
                  {tradePoolSwitch === 'Sell' && (
                    <div className="price_left">
                      <p className={'little-title'}>Buy Price</p>
                      <div className="price-amount">
                        <p>{currentPool?.pool_asset}</p>
                      </div>
                    </div>
                  )}
                  <div className="price_right">
                    {/*<p className={'little-title'}>Buy Price</p>*/}
                    {/*<div className="price-amount">*/}
                    {/*    <p>{currentPool?.pool_asset}</p>*/}
                    {/*</div>*/}
                  </div>
                </div>
              )}
              <div className="delta-balance">
                <div className="delta">
                  <p className="delta-title">Delta</p>
                  {currentPool?.linear_delta > 0 && (
                    <p className="delta-amount">
                      {currentPool?.linear_delta / Math.pow(10, 8)} {getCurrentToken(currentPool?.token_contract)}
                    </p>
                  )}
                  {currentPool?.exponential_delta > 0.0 && (
                    <p className="delta-amount">
                      {Number(currentPool?.exponential_delta).toFixed(4)}%
                    </p>
                  )}
                  {currentPool?.exponential_delta === 0.0 &&
                    currentPool?.linear_delta === 0 && (
                      <p className="delta-amount">
                        {currentPool?.linear_delta}
                      </p>
                    )}
                </div>
                <div className="balance">
                  <p className="balance-title">Balance</p>
                  {currentPool?.pool_nfts && currentLocation !== 'trade' && (
                    <p className="balance-amount">
                      {currentPool.pool_nfts.length}
                    </p>
                  )}
                  {currentPool?.pool_asset &&
                    currentLocation === 'trade' &&
                    tradePoolSwitch === 'Buy' && (
                      <p className="balance-amount">{currentPool.pool_asset}</p>
                    )}
                  {currentPool?.pool_nfts &&
                    currentLocation === 'trade' &&
                    tradePoolSwitch === 'Sell' && (
                      <p className="balance-amount">
                        {currentPool.pool_nfts.length}
                      </p>
                    )}
                </div>
              </div>
            </div>
            <div className="pool-owner">
              <p>Owner</p>
              <p>{currentPool?.pool_owner}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="nfts-block">
        {currentLocation === 'buy' && <h4>Select NFTs to sell</h4>}
        {currentLocation === 'sell' && <h4>Select NFTs for buy</h4>}
        <div className="nfts-block_list">
          {(currentLocation === 'buy' ||
            (currentLocation === 'trade' && tradePoolSwitch === 'Buy')) && (
            <div className="wrapper">
              {filteredNFTs &&
                filteredNFTs.map((item) => (
                  <div
                    className={`nfts-block_list_item ${
                      selectedAssets.includes(item?.asset_id) ? 'active' : ''
                    }`}
                    key={item?.asset_id}
                    onClick={() => addAsset(item)}
                  >
                    <div className="nfts-block_list_item-image">
                      {item?.data?.img?.startsWith('Qm') ? (
                        <img
                          src={`https://atomichub-ipfs.com/ipfs/${item?.data?.img}`}
                          alt=""
                        />
                      ) : (
                        <img src={item?.data?.img} alt="" />
                      )}
                    </div>
                    <div className="nfts-block_list_item-info">
                      <p>#{item?.asset_id}</p>
                      <h6>{shortenText(item?.data?.name, 15)}</h6>
                    </div>
                  </div>
                ))}
            </div>
          )}

          {(currentLocation === 'sell' ||
            (currentLocation === 'trade' && tradePoolSwitch === 'Sell')) && (
            <div className="wrapper">
              {!NFTonSale.length ? (
                <div className={'loader'}>
                  <LoaderData />
                </div>
              ) : (
                <>
                  {NFTonSale &&
                    NFTonSale.map((item) => (
                      <div
                        className={`nfts-block_list_item ${
                          selectedAssets.includes(item?.asset_id)
                            ? 'active'
                            : ''
                        }`}
                        key={item?.asset_id}
                        onClick={() => addAsset(item)}
                      >
                        <div className="nfts-block_list_item-image">
                          {item?.data?.img?.startsWith('Qm') ? (
                            <img
                              src={`https://atomichub-ipfs.com/ipfs/${item?.data?.img}`}
                              alt=""
                            />
                          ) : (
                            <img src={item?.data?.img} alt="" />
                          )}
                        </div>
                        <div className="nfts-block_list_item-info">
                          <p>#{item?.asset_id}</p>
                          <h6>{shortenText(item?.data?.name, 15)}</h6>
                        </div>
                      </div>
                    ))}

                  {/*{!allElementsLoaded &&*/}
                  {/*    <div className={'load-more-swap'}>*/}
                  {/*        <OrangeBorderButton onClick={loadMore}>Load more</OrangeBorderButton>*/}
                  {/*    </div>*/}
                  {/*}*/}
                </>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="total-price-block">
        {(currentLocation === 'buy' ||
          (currentLocation === 'trade' && tradePoolSwitch === 'Buy')) && (
          <>
            <h4>
              You will receive: <span>{totalCostWithFees.toFixed(currentPool?.token_contract === EOSIO_TOKEN ? 8 : 4)} {getCurrentToken(currentPool?.token_contract)}</span>
            </h4>
            <h4>
              and: <span>{tokenAmount.toFixed(4)} ALCH</span>
            </h4>
          </>
        )}

        {(currentLocation === 'sell' ||
          (currentLocation === 'trade' && tradePoolSwitch === 'Sell')) && (
          <h4>
            Will cost: <span>{totalCostWithOutFees.toFixed(currentPool?.token_contract === EOSIO_TOKEN ? 8 : 4)} {getCurrentToken(currentPool?.token_contract)}</span>
          </h4>
        )}
      </div>
      {(currentLocation === 'buy' ||
        (currentLocation === 'trade' && tradePoolSwitch === 'Buy')) && (
        <div className={'action-btn'}>
          <div className="cancel">
            <OrangeBorderButton onClick={() => goBack()} width={225}>
              Cancel
            </OrangeBorderButton>
          </div>
          <div className="confirm">
            <OrangeBorderButton
              onClick={() => handleSwapToBuyPool()}
              width={225}
            >
              Confirm Swap
            </OrangeBorderButton>
          </div>
        </div>
      )}

      {(currentLocation === 'sell' ||
        (currentLocation === 'trade' && tradePoolSwitch === 'Sell')) && (
        <div className={'action-btn'}>
          <div className="cancel">
            <OrangeBorderButton onClick={() => goBack()} width={225}>
              Cancel
            </OrangeBorderButton>
          </div>
          <div className="confirm">
            <OrangeBorderButton
              onClick={() => handleSwapToSellPool()}
              width={225}
            >
              Confirm Swap
            </OrangeBorderButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default SwapPage;
