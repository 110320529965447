import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BOTH, BUY, SELL, OFFER } from '../../../constants/pool.constants';
import WaxImg from '../../../images/wax_logo2.png';
import NFTImg from '../../../images/NFT.png';

const FirstStep = ({ currentStep, setSelectedOption, handleSelectOption }) => {
  const stakedTokens = useSelector((state) => state.stakedTokens);

  const [userStakedTokens, setUserStakedTokens] = useState(0);

  useEffect(() => {
    if (stakedTokens) {
      const parseStakedTokens = () => {
        setUserStakedTokens(parseFloat((stakedTokens[0]?.staked_tokens.split(' ')[0])));
      }
      parseStakedTokens();
    }
  }, [stakedTokens])

  return (
    <div className="create-pool-stepper-first">
      {currentStep === 1 && (
        <div
          className={`create-pool-stepper-first-block ${currentStep !== 1 ? 'hidden' : ''
            }`}
        >
          <div className="create-pool-stepper-first-block_item" onClick={() => {
            setSelectedOption(BUY);
            handleSelectOption(currentStep);
          }}>
            <div className="create-pool-stepper-first-block_item-title">
              <h3>Buy NFTs with tokens</h3>
            </div>
            <div className="create-pool-stepper-first-block_item-icons">
              <img src={WaxImg} alt="" />
              <span> &gt; </span>
              <img src={NFTImg} alt="" />
            </div>
            <div className="create-pool-stepper-first-block_item-about">
              <p>You will deposit tokens and receive NFTs as</p>
              <p>people swap their NFTs for your deposited tokens</p>
            </div>
          </div>

          <div className="create-pool-stepper-first-block_item" onClick={() => {
            setSelectedOption(SELL);
            handleSelectOption(currentStep);
          }}>
            <div className="create-pool-stepper-first-block_item-title">
              <h3>Sell NFTs with tokens</h3>
            </div>
            <div className="create-pool-stepper-first-block_item-icons">
              <img src={NFTImg} alt="" />
              <span> &gt; </span>
              <img src={WaxImg} alt="" />
            </div>
            <div className="create-pool-stepper-first-block_item-about">
              <p>You will deposit NFTs and receive tokens as</p>
              <p> people swap their tokens for your deposited NFTs</p>
            </div>
          </div>

          <div className="create-pool-stepper-first-block_item" onClick={() => {
            setSelectedOption(BOTH);
            handleSelectOption(currentStep);
          }}>
            <div className="create-pool-stepper-first-block_item-title">
              <h3>Do both and earn trading fees</h3>
            </div>
            <div className="create-pool-stepper-first-block_item-icons">
              <div className="create-pool-stepper-first-block_item-icons_both">
                <img src={WaxImg} alt="" />
                <img src={NFTImg} alt="" />
              </div>
              <span> &gt; </span>
              <span>%</span>

            </div>
            <div className="create-pool-stepper-first-block_item-about">
              <p>You will deposit both NFTs and tokens and earn</p>
              <p>trading fees as people buy or sell NFTs using your</p>
              <p>pool</p>
            </div>
          </div>
        </div>
      )}


      {currentStep === 1 && (
        <div className="alert-text">
          <p>Stake at least 50 ALCH to unlock Swap Pool</p>
        </div>
      )}

      {currentStep === 1 && (
        <div
          className={`create-pool-stepper-first-block ${currentStep !== 1 ? 'hidden' : ''
            }`}
        >
          <div className={`create-pool-stepper-first-block_item-offer ${userStakedTokens >= 50 ? '' : 'disabled'}`} onClick={userStakedTokens >= 50 ? () => {
            setSelectedOption(OFFER);
            handleSelectOption(currentStep);
          } : () => null}>
            <div className="create-pool-stepper-first-block_item-title">
              <h3>Swap NFT-2-NFT</h3>
            </div>
            <div className="create-pool-stepper-first-block_item-icons">
              <img src={NFTImg} alt="" />
              <span> &gt; </span>
              <img src={NFTImg} alt="" />
            </div>
            <div className="create-pool-stepper-first-block_item-about">
              <p>You can create a recipe to swap your own NFTs</p>
              <p>to your desired NFTs</p>
            </div>
          </div>

        </div>
      )}

    </div>
  );
};

export default FirstStep;