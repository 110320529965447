import React from 'react';
import Rodal from 'rodal';

import { Checkbox } from '@mui/material';

import OrangeBorderButton from '../../OrangeBorderButton/OrangeBorderButton';

import '../RodalCreatePool/RodalCreatePool.scss';

import '../RodalCreatePool/rodal.css';


function RodalDepositOfferPool({
  visible,
  hide,
  myNFTs,
  handleSelectItem,
  selectedNftToDeposit,
  collection,
  schema,
  templates
}) {

  const filteredNFTs = myNFTs
    ?.filter(
      (item) =>
        item.collection.collection_name === collection?.collection_name &&
        (!schema || item.schema?.schema_name === schema) &&
        (!templates.length || (item.template && templates.some((template) => template.template_id === item.template.template_id)))
    ) || [];

  return (
    <div className={'rodals'}>
      <Rodal
        className={'rodal-1'}
        visible={visible}
        onClose={hide}
        closeMaskOnClick={false}
      >
        <div className="title">
          <h2>Deposit NFT</h2>
          <p className={'sub-title'}>You can deposit NFT to your pool</p>
        </div>
        <div className="my-nft">
          <div className="my-nft_title">
            <h3>Selected NFTs: {selectedNftToDeposit.length}</h3>
            <OrangeBorderButton
              onClick={() => hide()}
            >
              Confirm
            </OrangeBorderButton>
          </div>
          <div className="my-nft-list">
            {myNFTs &&
              filteredNFTs
                .map((item, index) => {
                  return (
                    <div
                      className="my-nft-item"
                      key={index}
                      onClick={() => handleSelectItem(item)}
                    >
                      <div className="checked">
                        <Checkbox
                          checked={selectedNftToDeposit.some((selectedItem) => selectedItem.asset_id === item.asset_id)}
                          color="success"
                        />
                      </div>
                      <div className="image">
                        {item.data.img?.startsWith('Qm') ? (
                          <img
                            src={`https://atomichub-ipfs.com/ipfs/${item.data.img}`}
                            alt=""
                          />
                        ) : (
                          <img src={item.data.img} alt="" />
                        )}
                      </div>
                      <div className="collection-block">
                        <p>{item.collection.collection_name}</p>
                      </div>
                      <div className="asset-id">
                        <p>#{item.asset_id}</p>
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>
      </Rodal>
    </div>
  );
}

export default RodalDepositOfferPool;
