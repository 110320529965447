import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UALContext } from 'ual-reactjs-renderer';

import {
    getMyNFTs,
    getOfferPoolsByCollections,
    getSellPoolsByCollections,
    withdrawNfts,
} from '../../services/wax.services';

import ArrowIcon from '../../images/arrow2.png';
import ViewIcon from '../../images/view.png';
import LogoIcon from '../../images/logo@2x.png';
import WaxIcon from '../../images/wax_logo.png';
import LoaderData from '../../components/LoaderData/LoaderData';

import { getDataFromAtomicApi, shortenText } from '../../helpers';
import { toast } from 'react-toastify';


import RodalDepositPool from '../../components/Modal/RodalDepositPool/RodalDepositPool';
import { toastOptions } from '../../helpers/toast.helper';

import './PoolPageOffer.scss';

const PoolPageOffer = () => {
    const { name, id } = useParams();
    const navigate = useNavigate();
    const { activeUser } = useContext(UALContext);

    const [currentPool, setCurrentPool] = useState(null);

    const [isEditingDeposite, setIsEditingDeposite] = useState(false);
    const [isEditingWithdraw, setIsEditingWithdraw] = useState(false);

    const [NFTonSale, setNFTonSale] = useState([]);

    const [collectionImg, setCollectionImg] = useState('');

    const [selectedItemToWithdraw, setSelectedItemToWithdraw] = useState([]);

    const [visible, setVisible] = useState(false);
    const [myNFTs, setMyNFTs] = useState([]);

    const [selectedNftToDeposit, setSelectedNftToDeposit] = useState([]);

    useEffect(() => {
        getMyNFTs(activeUser?.accountName, name)
            .then((data) => {
                setMyNFTs(data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [activeUser, name]);

    const handleSelectItem = (item) => {
        if (selectedNftToDeposit.includes(item)) {
            setSelectedNftToDeposit((prevSelectedNftToDeposit) =>
                prevSelectedNftToDeposit.filter((selectedItem) => selectedItem !== item)
            );
        } else {
            setSelectedNftToDeposit((prevSelectedNftToDeposit) => [
                ...prevSelectedNftToDeposit,
                item,
            ]);
        }
    };

    const toggleEditing = (type) => {
        if (type === 'deposit') {
            setVisible(true);
            setIsEditingDeposite(!isEditingDeposite);
        }

        if (type === 'withdraw') setIsEditingWithdraw(!isEditingWithdraw);
    };

    const hide = () => {
        setVisible(false);
    };

    const handleToWithdraw = (item) => {
        const index = selectedItemToWithdraw.indexOf(item);
        if (index === -1) {
            setSelectedItemToWithdraw([...selectedItemToWithdraw, item]);
        } else {
            const updatedItems = [...selectedItemToWithdraw];
            updatedItems.splice(index, 1);
            setSelectedItemToWithdraw(updatedItems);
        }
    };

    const handleSave = async () => {
        try {
            if (isEditingDeposite) {
                setIsEditingDeposite(false);
            }

            if (isEditingWithdraw && selectedItemToWithdraw.length) {
                await withdrawNfts({ activeUser, selectedItemToWithdraw, id });
                toast.success('Success', toastOptions);

                setTimeout(async () => {
                    const data = await getSellPoolsByCollections();
                    const pool = data.find((item) => item.pool_id === parseInt(id));

                    setCurrentPool(pool);
                    setNFTonSale(pool.pool_nfts);

                    setSelectedItemToWithdraw([]);
                }, 1000);
            }
        } catch (error) {
            const errorMessage = error.message.replace(
                'assertion failure with message: ',
                ''
            );
            toast.error(errorMessage, toastOptions);
            console.error(error);
        } finally {
            setIsEditingWithdraw(false);
        }
    };

    const handleClickBack = () => {
        navigate(`/collections/${name}`);
    };

    const handleClickDirect = () => {
        navigate(`/collections/${name}/swap/offer/${id}`);
    };

    useEffect(() => {
        const fetchPool = async () => {
            await getOfferPoolsByCollections()
                .then((data) => {
                    const pool = data.find((item) => item.pool_id === parseInt(id));

                    setCurrentPool(pool);
                    setNFTonSale(pool.pool_nfts);
                })
                .catch((error) => {
                    console.log(error);
                });
        };

        fetchPool();
    }, [id]);


    useEffect(() => {
        async function fetchCollectionImage() {
            if (currentPool?.NFTs_collection.collection_name) {
                try {
                    const data = await getDataFromAtomicApi(
                        `collections/${currentPool.NFTs_collection.collection_name}`
                    );
                    setCollectionImg(data.img);
                } catch (error) {
                    console.log(error);
                }
            }
        }

        fetchCollectionImage();
    }, [currentPool]);

    return (
        <div className={'pool-page-section'}>
            <div className="pool-page-section_title">
                <div className="name-collection">
                    {collectionImg && (
                        <img
                            src={`https://atomichub-ipfs.com/ipfs/${collectionImg}`}
                            alt=""
                        />
                    )}
                    <p>{name}</p>
                </div>
                <div className="arrow">
                    <img src={ArrowIcon} alt="" />
                </div>
                <div className="name-collection">
                    {collectionImg && (
                        <img
                            src={`https://atomichub-ipfs.com/ipfs/${collectionImg}`}
                            alt=""
                        />
                    )}
                    <p>{name}</p>
                </div>
            </div>

            <div className="action-buttons">
                <div className="view-all" onClick={handleClickBack}>
                    <div className="view-all-fst">
                        <img src={ViewIcon} alt="" />
                        <p>View All</p>
                    </div>
                    <div className="view-all-snd">
                        {collectionImg && (
                            <img
                                src={`https://atomichub-ipfs.com/ipfs/${collectionImg}`}
                                alt=""
                            />
                        )}
                        <p>{name}</p>
                    </div>
                </div>

                {activeUser &&
                    currentPool &&
                    currentPool.pool_owner &&
                    activeUser.accountName !== currentPool.pool_owner &&
                    currentPool?.pool_nfts.length !== 0 && (
                        <div className="direct-swap" onClick={handleClickDirect}>
                            <img src={LogoIcon} alt="" />
                            <p>Swap with this pool</p>
                        </div>
                    )}
            </div>

            <div className="assets-block-offer">
                <div className="assets">
                    <div className="assets_title">
                        <h5>Assets</h5>
                    </div>

                    <div className="deposit-field">
                        <div className="wrapper">
                            <div className="nfts">
                                <p>NFTs</p>
                                <p>
                                    {currentPool?.pool_nfts.length === 0
                                        ? 'Empty pool'
                                        : currentPool?.pool_nfts.length}
                                </p>
                            </div>
                            {activeUser &&
                                currentPool &&
                                currentPool.pool_owner &&
                                activeUser.accountName === currentPool.pool_owner && (
                                    <div className="deposit-withdraw-btn">
                                        <button
                                            className="deposit-btn"
                                            onClick={() => toggleEditing('deposit')}
                                        >
                                            Deposit
                                        </button>
                                        {isEditingWithdraw ? (
                                            <button className="save-btn" onClick={handleSave}>
                                                Save
                                            </button>
                                        ) : (
                                            <button
                                                className="withdraw-btn"
                                                onClick={() => toggleEditing('withdraw')}
                                            >
                                                Withdraw
                                            </button>
                                        )}
                                    </div>
                                )}
                        </div>
                    </div>
                    {!isEditingWithdraw ? (
                        <div className="nfts-field">
                            {!NFTonSale.length ? (
                                <LoaderData />
                            ) : (
                                <>
                                    <div className="nfts-field-list">
                                        {NFTonSale &&
                                            NFTonSale.map((item) => {
                                                const imgUrl = item?.data?.img?.startsWith('Qm')
                                                    ? `https://atomichub-ipfs.com/ipfs/${item?.data?.img}`
                                                    : item?.data?.img;

                                                return (
                                                    <div
                                                        className="nfts-field-list_item"
                                                        key={item?.asset_id}
                                                    >
                                                        <div className="nfts-field-list_item-image">
                                                            <img src={imgUrl} alt="" />
                                                        </div>
                                                        <div className="nfts-field-list_item-info">
                                                            <p>#{item?.asset_id}</p>
                                                            <h6>{shortenText(item?.data?.name, 15)}</h6>
                                                        </div>
                                                    </div>
                                                );
                                            })}

                                    </div>
                                </>
                            )}
                        </div>
                    ) : (
                        <div className="nfts-field withdraw">
                            {!NFTonSale.length ? (
                                <LoaderData />
                            ) : (
                                <>
                                    <div className="nfts-field-list">
                                        {NFTonSale &&
                                            NFTonSale.map((item) => {
                                                const imgUrl = item?.data?.img?.startsWith('Qm')
                                                    ? `https://atomichub-ipfs.com/ipfs/${item?.data?.img}`
                                                    : item?.data?.img;

                                                return (
                                                    <div
                                                        className={`nfts-field-list_item withdraw ${selectedItemToWithdraw.includes(item?.asset_id)
                                                            ? 'active'
                                                            : ''
                                                            }`}
                                                        key={item?.asset_id}
                                                        onClick={() => handleToWithdraw(item?.asset_id)}
                                                    >
                                                        <div className="nfts-field-list_item-image">
                                                            <img src={imgUrl} alt="" />
                                                        </div>
                                                        <div className="nfts-field-list_item-info">
                                                            <p>#{item?.asset_id}</p>
                                                            <h6>{shortenText(item?.data?.name)}</h6>
                                                        </div>
                                                    </div>
                                                );
                                            })}

                                    </div>
                                </>
                            )}
                        </div>
                    )}
                    <div className="about-owner">
                        <div className="about-owner_title">
                            <h5>About</h5>
                        </div>
                        <div className="about-owner_info">
                            <p>Pool owner:</p>
                            <div className="about-owner_info-address">
                                <p>{currentPool?.pool_owner}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="about">
                        <div className="rules">
                            <div className="rules_title">
                                <h5>Rules</h5>
                            </div>
                            <div className="rules_subtitle">
                                <p>Specific rules for this NFT1 to NFT2 pool</p>
                            </div>
                            <div className="rules_collection-block">
                                <p>You choose from the pool</p>
                                <div className="collection-inside">
                                    <p className='amount-nft'>{currentPool && currentPool.user_receiving_amount} NFT</p>
                                    <div className="name-collection">
                                        {collectionImg && (
                                            <img
                                                src={`https://atomichub-ipfs.com/ipfs/${collectionImg}`}
                                                alt=""
                                            />
                                        )}
                                        <p>{name}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="rules_schema">

                                {currentPool && currentPool.schemas.length !== 0 && <span>Schema</span>}
                                {currentPool && currentPool.schemas.map((schema, index) => (
                                    <React.Fragment key={index}>
                                        {index > 0 && ', '}
                                        <p>{schema}</p>
                                    </React.Fragment>
                                ))}
                            </div>

                            <div className="rules_template">
                                {currentPool && currentPool.templates.length !== 0 && <span className='final-info_template-span'>Template</span>}
                                {currentPool && currentPool.templates.map(template =>
                                    <div className="templates-item">
                                        <div className="templates-item_info">
                                            <p className='info'>#{template}</p>
                                        </div>
                                    </div>
                                )}
                            </div>

                        </div>

                    </div>
                    <div className="final-info">
                        <div className="final-info_block">
                            <div className="final-info_block-title">
                                <p className='subtitle'>You must deposit to the pool</p>
                                <div className="final-info_block-info">
                                    <p className='amount'>{currentPool && currentPool.owner_receiving_amount} NFT</p>
                                    <div className="name-collection">
                                        {collectionImg && (
                                            <img
                                                src={`https://atomichub-ipfs.com/ipfs/${collectionImg}`}
                                                alt=""
                                            />
                                        )}
                                        <p className='collection-name'>{name}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="final-info_block-title-second">
                                <p className='subtitle'>/ and — /</p>
                                <div className="final-info_block-info">
                                <img src={WaxIcon} alt="" />
                                    <p className='amount'>{currentPool && Number(currentPool.additional_price.split(' ')[0]).toFixed(0)} WAX</p>
                                </div>
                            </div>


                        </div>

                        <div className="final-info_schema">
                            {currentPool && currentPool.schemas.length !== 0 && <span className='final-info_schemas'>Schema</span>}
                            {currentPool && currentPool.schemas.map((schema, index) => (
                                <React.Fragment key={index}>
                                    {index > 0 && ', '}
                                    <p className='final-info_schema-p'>{schema}</p>
                                </React.Fragment>
                            ))}
                        </div>
                        <div className="final-info_template">
                            {currentPool && currentPool.user_required_templates.length !== 0 && <span className='final-info_template-span'>Template</span>}
                            {currentPool && currentPool.user_required_templates.map(template =>
                                <div className="templates-item">
                                    <div className="templates-item_amount">
                                        <span className='templates-item_amount-span'>Amount</span>
                                        <p className='number'>{template.value}</p>
                                    </div>
                                    <div className="templates-item_info">
                                        <p className='info'>#{template.key}</p>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>

                    {/* {activeUser &&
                    currentPool &&
                    currentPool.pool_owner &&
                    activeUser.accountName !== currentPool.pool_owner &&
                    currentPool?.pool_nfts.length !== 0 && (
                        <div className="direct-swap" onClick={handleClickDirect}>
                            <p>Swap with this pool</p>
                        </div>
                    )} */}
                </div>
            </div>

            <RodalDepositPool
                visible={visible}
                hide={hide}
                myNFTs={myNFTs}
                setMyNFTs={setMyNFTs}
                handleSelectItem={handleSelectItem}
                selectedNftToDeposit={selectedNftToDeposit}
                setSelectedNftToDeposit={setSelectedNftToDeposit}
                id={id}
                user={activeUser}
                name={name}
                setCurrentPool={setCurrentPool}
                setNFTonSale={setNFTonSale}
                setSelectedItemToWithdraw={setSelectedItemToWithdraw}
            />

        </div>
    );
};

export default PoolPageOffer;