import React, { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { UALContext } from 'ual-reactjs-renderer';

import {
  depositToken,
  getBuyPoolsByCollections,
  updateBuyPool,
  withdrowToken,
} from '../../services/wax.services';
import { LinearDiagramForBuyPool } from '../../components/LinearDiagramForBuyPool/LinearDiagramForBuyPool';
import SliderS from '../../components/Slider/Slider';

import WaxLogo from '../../images/wax_logo2.png';
import ArrowIcon from '../../images/arrow2.png';
import WuffiToken from '../../images/wuffi-token.png'

import { ExponentialDiagramForBuyPool } from '../../components/ExponentialDiagramForBuyPool/ExponentialDiagramForBuyPool';
import { toast } from 'react-toastify';
import Rodal from 'rodal';
import OrangeBorderButton from '../../components/OrangeBorderButton/OrangeBorderButton';
import { getCurrentToken, getDataFromAtomicApi, getTokenIcon } from '../../helpers';
import ViewIcon from '../../images/view.png';
import LogoIcon from '../../images/logo@2x.png';
import { toastOptions } from '../../helpers/toast.helper';

import './PoolPageBuy.scss';

const PoolPageBuy = () => {
  const navigate = useNavigate();
  const { name, id } = useParams();
  const { activeUser } = useContext(UALContext);

  const [currentPool, setCurrentPool] = useState(null);
  const [delta, setDelta] = useState(0);

  // const [isEditing, setIsEditing] = useState(false);

  const [sliderValue, setSliderValue] = useState(0);

  const [amountNft, setAmountNft] = useState(0);

  // const [mainConfig, setMainConfig] = useState([]);

  const [isEditingDeposite, setIsEditingDeposite] = useState(false);
  const [isWithdrow, setIsWithdrow] = useState(false);
  const [amountDeposite, setAmountDeposite] = useState(currentPool?.pool_asset);

  const [finalPrice, setFinalPrice] = useState(0);
  const [finalPriceForExpon, setFinalPriceForExpon] = useState(0);

  const [currentDelta, setCurrentDelta] = useState('Linear');

  const [visible, setVisible] = useState(false);
  const [collectionImg, setCollectionImg] = useState('');

  const [newSchema, setNewSchema] = useState('');
  const [newCollections, setNewCollections] = useState('');

  const toggleEditing = (type) => {
    if (type === 'deposit') setIsEditingDeposite(!isEditingDeposite);

    if (type === 'withdraw') setIsWithdrow(!isWithdrow);
  };

  const show = () => {
    setVisible(true);
  };

  const hide = () => {
    setVisible(false);
  };

  const handleSave = () => {
    if (!amountDeposite) {
      setIsEditingDeposite(false);
      setIsWithdrow(false);
      return;
    } else {
      setAmountDeposite(null);
    }

    if (isEditingDeposite) {
      handleDeposit(amountDeposite);
      setIsEditingDeposite(false);
    }

    if (isWithdrow) {
      handleWithdrow(amountDeposite);
      setIsWithdrow(false);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const fetchPool = async () => {
      try {
        const buyPools = await getBuyPoolsByCollections({ signal });
        const pool = buyPools.find((item) => item.pool_id === parseInt(id));
        setCurrentPool(pool);

        if (
          pool.linear_delta === 0 &&
          pool.exponential_delta === '0.00000000000000000'
        ) {
          setCurrentDelta('Linear');
        } else if (pool.linear_delta !== 0) {
          setCurrentDelta('Linear');
        } else {
          setCurrentDelta('Exponential');
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchPool();

    return () => {
      abortController.abort();
    };
  }, [id]);

  useEffect(() => {
    if (currentPool) {
      let pool_asset = currentPool.pool_asset.split(' ')[0];
      let floor_price = currentPool.floor_price.split(' ')[0];
      let result = pool_asset / floor_price;

      let integerResult = Math.floor(result);

      setAmountNft(integerResult);
    }
  }, [currentPool]);

  useEffect(() => {
    const feePercentage = 1;
    const floorPrice = Number(currentPool?.floor_price?.split(' ')[0]) || 0;

    if (sliderValue === 0) {
      setFinalPrice(0);
      return;
    }

    let totalPrice = floorPrice;
    for (let i = 2; i <= sliderValue; i++) {
      totalPrice += floorPrice - delta * (i - 1);
    }

    let finalPrice = totalPrice * (1 - feePercentage / 100);
    setFinalPrice(finalPrice.toFixed(8));
  }, [sliderValue, currentPool, delta]);

  useEffect(() => {
    let floorPrice = Number(currentPool?.floor_price?.split(' ')[0]) || 0;

    if (sliderValue === 0) {
      setFinalPriceForExpon(0);
      return;
    }

    let totalPrice = floorPrice;
    let previousPrice = floorPrice;
    for (let i = 2; i <= sliderValue; i++) {
      previousPrice = previousPrice - previousPrice * (delta / 100);
      totalPrice += previousPrice;
    }

    let finalPrice = totalPrice * (1 - 0.01);
    setFinalPriceForExpon(finalPrice.toFixed(8));
  }, [sliderValue, currentPool, delta]);

  useEffect(() => {
    if (!currentPool) return;
    if (currentPool.linear_delta > 0) {
      setDelta(currentPool.linear_delta / Math.pow(10, 8));
    } else if (currentPool.exponential_delta !== '0.00000000000000000') {
      setDelta(parseFloat(currentPool.exponential_delta));
    } else {
      setDelta(0);
    }
  }, [currentPool]);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getDataFromAtomicApi(
          `collections/${currentPool?.NFTs_collection?.collection_name}`
        );
        setCollectionImg(data.img);
      } catch (error) {
        // Handle errors if necessary
      }
    }

    fetchData();

    // Add other dependencies if needed
  }, [currentPool]);

  // const handleEditClick = () => {
  //     show()
  // };

  const handleUpdateBuyPool = () => {
    updateBuyPool(activeUser, id, {});
  };

  // const handleSaveClick = () => {
  //     setIsEditing(false);
  //
  //     // setCurrentPool({
  //     //     ...currentPool,
  //     //     floor_price: editedPool,
  //     //     linear_delta: editedDelta,
  //     // });
  // };

  const handleDeposit = (amount) => {
    depositToken({ activeUser, amount: amount, pool_id: id })
      .then(() => {
        // getBuyPoolsByCollections()

        toast.success('Deposited', toastOptions);
      })
      .catch((error) => {
        const errorMessage = error.message.replace(
          'assertion failure with message: ',
          ''
        );
        toast.error(errorMessage, toastOptions);
        console.log(error);
      });
  };

  const handleWithdrow = (amount) => {
    withdrowToken({ activeUser, amount: amount, pool_id: id })
      .then(() => {
        // getBuyPoolsByCollections()

        toast.success('Success', toastOptions);
      })
      .catch((error) => {
        const errorMessage = error.message.replace(
          'assertion failure with message: ',
          ''
        );
        toast.error(errorMessage, toastOptions);
        console.log(error);
      });
  };

  const handleClickBack = () => {
    navigate(`/collections/${name}`);
  };

  const handleClickDirect = () => {
    navigate(`/collections/${name}/swap/buy/${id}`);
  };

  return (
    <div className={'pool-page-section'}>
      <div className="pool-page-section_title">
      {currentPool &&
          <div className="wax-title">
            {getTokenIcon(currentPool.token_contract)}
            <p>{getCurrentToken(currentPool.token_contract)}</p>
          </div>
        }
        <div className="arrow">
          <img src={ArrowIcon} alt="" />
        </div>
        <div className="name-collection">
          {collectionImg && (
            <img
              src={`https://atomichub-ipfs.com/ipfs/${collectionImg}`}
              alt=""
            />
          )}
          <p>{name}</p>
        </div>
      </div>

      <div className="action-buttons">
        <div className="view-all" onClick={handleClickBack}>
          <div className="view-all-fst">
            <img src={ViewIcon} alt="" />
            <p>View All</p>
          </div>
          <div className="view-all-snd">
            {collectionImg && (
              <img
                src={`https://atomichub-ipfs.com/ipfs/${collectionImg}`}
                alt=""
              />
            )}
            <p>{name}</p>
          </div>
        </div>

        {activeUser &&
          currentPool &&
          currentPool.pool_owner &&
          activeUser.accountName !== currentPool.pool_owner &&
          parseFloat(currentPool.pool_asset) >=
            parseFloat(currentPool.floor_price) && (
            <div className="direct-swap" onClick={handleClickDirect}>
              <img src={LogoIcon} alt="" />
              <p>Sell NFTs into pool</p>
            </div>
          )}
      </div>
      <div className="assets-block-buy">
        <div className="assets">
          <div className="assets_title">
            <h5>Assets</h5>
          </div>

          <div className="deposit-field">
            <div className="wrapper">
              <div className="deposit-field-tokens">
                <p className="little-title">Tokens</p>
                {isEditingDeposite || isWithdrow ? (
                  <input
                    type="number"
                    value={amountDeposite}
                    onChange={(e) => setAmountDeposite(e.target.value)}
                  />
                ) : (
                  <p className="amount-token">{currentPool?.pool_asset}</p>
                )}
              </div>
              {activeUser &&
                currentPool &&
                currentPool.pool_owner &&
                activeUser.accountName === currentPool.pool_owner && (
                  <div className="deposit-withdraw-btn">
                    {isEditingDeposite ? (
                      <button className="save-btn" onClick={handleSave}>
                        Save
                      </button>
                    ) : (
                      <button
                        className="deposit-btn"
                        onClick={() => toggleEditing('deposit')}
                      >
                        Deposit
                      </button>
                    )}
                    {isWithdrow ? (
                      <button className="save-btn" onClick={handleSave}>
                        Save
                      </button>
                    ) : (
                      <button
                        className="deposit-btn"
                        onClick={() => toggleEditing('withdraw')}
                      >
                        Withdrow
                      </button>
                    )}
                  </div>
                )}
            </div>
          </div>
        </div>
        <div className="about">
          <div className="pricing">
            {activeUser &&
              currentPool &&
              currentPool.pool_owner &&
              activeUser.accountName === currentPool.pool_owner && (
                <div className="edit">
                  <button onClick={() => show()}>Edit</button>
                </div>
              )}

            <div className="pricing_title">
              <h5>Pricing</h5>
            </div>
            <div className="pricing-info">
              <div className="current-price">
                <div className="wrapper">
                  <div className="little-title">
                    <p>Current Price</p>
                  </div>
                  <div className={'floor-price'}>
                    <p>{currentPool?.floor_price}</p>
                  </div>
                </div>
              </div>
              <div className="delta">
                <div className="wrapper">
                  <div className="little-title">
                    <p>Delta: {currentDelta}</p>
                  </div>
                  <>
                    {currentDelta === 'Linear' && currentPool && (
                      <div className={'delta-number'}>
                        <p>{delta} {getCurrentToken(currentPool.token_contract)}</p>
                      </div>
                    )}
                    {currentDelta === 'Exponential' && (
                      <div className={'delta-number'}>
                        <p>{delta}%</p>
                      </div>
                    )}
                  </>
                </div>
              </div>
            </div>
          </div>
          <div className="about-owner">
            <div className="about-owner_title">
              <h5>About</h5>
            </div>
            <div className="about-owner_info">
              <p>Pool owner:</p>
              <div className="about-owner_info-address">
                <p>{currentPool?.pool_owner}</p>
              </div>
            </div>
            <div className="about-owner_pool-info">
              {currentPool && <div>
                This pool holds{' '}
                <img className={'mini-wax-logo'} src={currentPool.token_contract === 'eosio.token' ? WaxLogo : WuffiToken} alt="" /> {getCurrentToken(currentPool.token_contract)} and
                buys {currentPool?.NFTs_collection?.collection_name}
              </div>
              }
              <div>
                Right now this pool will buy at{' '}
                <span>{currentPool?.floor_price}</span>
              </div>
              <div>
                Each time this pool buys an NFT, the price will be lowered by{' '}
                {currentDelta === 'Linear' && currentPool && <span>{delta} {getCurrentToken(currentPool.token_contract)}</span>}{' '}
                {currentDelta === 'Exponential' && <span>{delta}%</span>}{' '}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="diagram-block">
        <div className="diagram-block_title">
          <h5>Price Graph</h5>
        </div>
        <div className="amount-slider">
          <h4>
          Selling <span>{sliderValue} NFTs</span>
          </h4>
          <SliderS
            setSliderValue={setSliderValue}
            slideValue={sliderValue}
            amount={amountNft}
          />
          {currentDelta === 'Linear' && currentPool && (
            <p>
              will earn this pool <span>{finalPrice} {getCurrentToken(currentPool.token_contract)}</span>
            </p>
          )}
          {currentDelta === 'Exponential' && currentPool && (
            <p>
              will earn this pool <span>{finalPriceForExpon} {getCurrentToken(currentPool.token_contract)}</span>
            </p>
          )}
        </div>
        <div className="diagram-block-content">
          {currentDelta === 'Linear' && (
            <LinearDiagramForBuyPool
              startPrice={currentPool?.floor_price.split(' ')[0]}
              delta={delta}
              amount={amountNft}
              slideValue={sliderValue}
            />
          )}
          {currentDelta === 'Exponential' && (
            <ExponentialDiagramForBuyPool
              startPrice={currentPool?.floor_price.split(' ')[0]}
              delta={delta}
              amount={amountNft}
              slideValue={sliderValue}
            />
          )}
        </div>
      </div>

      <Rodal className={'rodal-update-pool'} visible={visible} onClose={hide}>
        <div className="rodal-title">
          <h3>Update pool</h3>
        </div>
        <div className="rodal-form">
          <div className="rodal-form_field">
            <p>New schemas</p>
            <input
              value={newSchema}
              onChange={(e) => setNewSchema(e.target.value)}
              type="text"
            />
          </div>
          <div className="rodal-form_field">
            <p>New Templates</p>
            <input type="text" />
          </div>
          <div className="rodal-form_field">
            <p>New NFTs collection</p>
            <input
              value={newCollections}
              onChange={(e) => setNewCollections(e.target.value)}
              type="text"
            />
          </div>
          <div className="rodal-form_field">
            <p>New floor price</p>
            <input type="text" />
          </div>
          <div className="rodal-form_field">
            <p>New linear delta</p>
            <input type="text" />
          </div>
          <div className="rodal-form_field">
            <p>New exponential delta</p>
            <input type="text" />
          </div>
        </div>
        <div className="update-btn">
          <OrangeBorderButton onClick={() => handleUpdateBuyPool()}>
            Update
          </OrangeBorderButton>
        </div>
      </Rodal>
    </div>
  );
};

export default PoolPageBuy;
