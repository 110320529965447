import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { UALContext } from 'ual-reactjs-renderer';
import { acceptOffer, getMyNFTs, getOfferPoolsByCollections } from '../../services/wax.services';


import { shortenText } from '../../helpers';
import NavigationButtonsOfferPool from '../../components/NavigationButtonsOfferPool/NavigationButtonsOfferPool';
import { toast } from 'react-toastify';
import { toastOptions } from '../../helpers/toast.helper';

import './SwapOfferPage.scss';

const SwapOfferPage = () => {
  const { name, id } = useParams();
  const { activeUser } = useContext(UALContext);
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(1); // Added state for managing steps

  const [currentPool, setCurrentPool] = useState(null);
  const [myNFTs, setMyNFTs] = useState([]);
  // const [mainConfig, setMainConfig] = useState([]);
  // const [feesConfig, setFeesConfig] = useState(0);

  const [selectedAssetsFirst, setSelectedAssetsFirst] = useState([]);
  const [selectedAssetsSecond, setSelectedAssetsSecond] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchMyNFTs = async () => {
      try {
        const data = await getMyNFTs(activeUser?.accountName, name);
        setMyNFTs(data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchMyNFTs();
  }, [activeUser, name]);

  // useEffect(() => {
  //   const fetchMainConfig = async () => {
  //     try {
  //       const data = await getMainConfig();
  //       setMainConfig(data);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   fetchMainConfig();
  // }, []);

  // useEffect(() => {
  //   const fetchFeesConfig = async () => {
  //     try {
  //       const data = await getFeesConfig();
  //       const filteredData = data.filter((item) => item.token_contract === EOSIO_TOKEN);
  //       setFeesConfig(filteredData[0]);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   fetchFeesConfig();
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getOfferPoolsByCollections();
        const pool = data.find((item) => item.pool_id === parseInt(id));

        setCurrentPool(pool);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [id]);

  const handleNextClick = () => {
    setLoading(true);

    if (currentStep === 1) {
      // Add logic for Step 1 completion
      // For example, fetch data or perform operations
      setTimeout(() => {
        setLoading(false);
        setCurrentStep(2);
      }, 1000);
    } else if (currentStep === 2) {
      // Add logic for Step 2 completion
      // For example, validate user's NFT selection against rules
      setTimeout(() => {
        setLoading(false);
        setCurrentStep(3);
      }, 1000);
    } else {
      // Handle Step 3 completion logic
      // For example, show confirmation message
      setTimeout(() => {
        setLoading(false);
        // Additional logic for Step 3 completion
      }, 1000);
    }
  };


  const getStepSubtitle = (step) => {
    switch (step) {
      case 1:
        return 'Select NFTs from the pool to receive';
      case 2:
        return 'Select your own NFTs which match the rules';
      case 3:
        return 'Confirmation';
      default:
        return '';
    }
  };

  const confirmSwapOfferPool = async () => {
    await acceptOffer({
      activeUser,
      id,
      selectedAssetsFirst,
      selectedAssetsSecond,
      currentPool
    })
      .then(() => {
        toast.success('Swapped successfuly', toastOptions);

        navigate('/pools');
      })
      .catch((error) => {
        const errorMessage = error.message.replace(
          'assertion failure with message: ',
          ''
        );
        toast.error(errorMessage, toastOptions);
        console.log(error);
      });
  }

  const handleBackClick = () => {
    if (currentStep > 1) {
      setLoading(true);
      setSelectedAssetsFirst([])
      setSelectedAssetsSecond([])
      setTimeout(() => {
        setLoading(false);
        setCurrentStep(currentStep - 1);
      }, 1000);
    } else {
      navigate(-1)
    }
  };

  const handleCancel = () => {
    setSelectedAssetsFirst([])
    setSelectedAssetsSecond([])
    navigate(-1)
  };

  const addAsset = (item) => {
    console.log(item);
    if (!item) {
      return;
    }

    if (selectedAssetsFirst.includes(item)) {
      setSelectedAssetsFirst(
        selectedAssetsFirst.filter((id) => id.asset_id !== item.asset_id)
      );
    } else {
      setSelectedAssetsFirst(selectedAssetsFirst.concat(item));
    }
  };

  const addAssetTo = (item) => {
    if (!item) {
      return;
    }

    if (selectedAssetsSecond.includes(item)) {
      setSelectedAssetsSecond(
        selectedAssetsSecond.filter((id) => id.asset_id !== item.asset_id)
      );
    } else {
      setSelectedAssetsSecond(selectedAssetsSecond.concat(item));
    }
  };


  return (
    <div className="swap-page-offer">
      <div className="swap-page-offer_title">
        <h2>Swap</h2>
      </div>
      <div className="swap-page-offer_subtitle">
        <p>Step {currentStep}: {getStepSubtitle(currentStep)}</p>
      </div>

      {loading ? (
        <div className="loading-block">
          <p>Loading...</p>
        </div>
      ) : (
        <div className="pool-infos">
          {/* Add conditional rendering based on the current step */}
          {currentStep === 1 && (
            <>
              <div className="pool-block">
                <div className="pool-block_wrapper">
                  <div className="collections">
                    <span>Collection</span>
                    <p>{name}</p>
                  </div>
                  {currentPool && currentPool.schemas.length !== 0 && (
                    <div className="schemas">
                      <span>Schema</span>
                      <p>{currentPool.schemas.join(', ')}</p>
                    </div>
                  )}

                  {currentPool && currentPool.templates.length !== 0 && (
                    <div className="templates">
                      <span>Template</span>
                      <p>#{currentPool.templates.join(', ')}</p>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className="nfts-block">
                  <div className="nfts-block_wrapper">
                    <div className="nfts-block-filters">

                    </div>

                    <div className="nfts-block_list">
                      {currentPool && currentPool.pool_nfts && currentPool.pool_nfts.map((item) =>
                        <div
                          className={`nfts-block_list_item ${selectedAssetsFirst.includes(item)
                            ? 'active'
                            : ''
                            }`}
                          key={item?.asset_id}
                          onClick={() => addAsset(item)}
                        >
                          <div className="nfts-block_list_item-image">
                            {item?.data?.img?.startsWith('Qm') ? (
                              <img
                                src={`https://atomichub-ipfs.com/ipfs/${item?.data?.img}`}
                                alt=""
                              />
                            ) : (
                              <img src={item?.data?.img} alt="" />
                            )}
                          </div>
                          <div className="nfts-block_list_item-info">
                            <p>#{item?.asset_id}</p>
                            <h6>{shortenText(item?.data?.name, 15)}</h6>
                          </div>
                        </div>
                      )}

                    </div>

                  </div>
                </div>
                <div className="amount-block">
                  <p>Selected NFTs: {selectedAssetsFirst.length}</p>
                </div>
              </div>
            </>
          )}
          {currentStep === 2 && (
            <>
              <div className="pool-block">
                <div className="pool-block_wrapper">
                  <div className="collections">
                    <span>Collection</span>
                    <p>{name}</p>
                  </div>
                  {currentPool && currentPool.schemas.length !== 0 && (
                    <div className="schemas">
                      <span>Schema</span>
                      <p>{currentPool.schemas.join(', ')}</p>
                    </div>
                  )}
                  {currentPool && currentPool.user_required_templates.length !== 0 &&
                    <div className="templates">
                      <span>Template</span>
                      <div className="templates-list">
                        {currentPool.user_required_templates.map((item, index) => {
                          const count = selectedAssetsSecond.reduce((accumulator, asset) => {
                            return Number(asset.template.template_id) === Number(item.key) ? accumulator + 1 : accumulator;
                          }, 0);

                          return (
                            <div className="templates-item" key={index}>
                              <div className="templates-item_amount">
                                <span>Amount</span>
                                <p>{count}/{item.value}</p>
                              </div>
                              <div className="templates-item_info">
                                <p>#{item.key}</p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  }
                </div>
              </div>
              <div>
                <div className="nfts-block">
                  <div className="nfts-block_wrapper">
                    <div className="nfts-block-filters">

                    </div>
                    <div className="nfts-block_list">
                      {myNFTs && myNFTs
                        .filter(item => currentPool.user_required_templates.some(template => Number(template.key) === Number(item.template.template_id)))
                        .map((item) =>
                          <div
                            className={`nfts-block_list_item ${selectedAssetsSecond.includes(item)
                              ? 'active'
                              : ''
                              }`}
                            key={item?.asset_id}
                            onClick={() => addAssetTo(item)}
                          >
                            <div className="nfts-block_list_item-image">
                              {item?.data?.img?.startsWith('Qm') ? (
                                <img
                                  src={`https://atomichub-ipfs.com/ipfs/${item?.data?.img}`}
                                  alt=""
                                />
                              ) : (
                                <img src={item?.data?.img} alt="" />
                              )}
                            </div>
                            <div className="nfts-block_list_item-info">
                              <p>#{item?.asset_id}</p>
                              <h6>{shortenText(item?.data?.name, 15)}</h6>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                <div className="amount-block">
                  <p>Selected NFTs: {selectedAssetsSecond.length}</p>
                </div>
              </div>
            </>
          )}
          {currentStep === 3 && (
            <div className="confirmation-block">
              <div className="confirmation-block_receive">
                <div className="title">
                  <h5>You receive:</h5>
                </div>
                <div className="asset-list">
                  {selectedAssetsFirst && selectedAssetsFirst.map((asset, index) =>
                    <div className="asset-item" key={index}>
                      {asset.data?.img?.startsWith('Qm') ? (
                        <img
                          src={`https://atomichub-ipfs.com/ipfs/${asset.data?.img}`}
                          alt=""
                          className='asset-item_img'
                        />
                      ) : (
                        <img src={asset.data?.img} alt="" />
                      )}
                      <p className='asset-item_id'>#{asset.asset_id}</p>
                      <p className='asset-item_name'>{asset.name}</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="confirmation-block_send">
                <div className="title">
                  <h5>You send:</h5>
                </div>
                <div className="asset-list">
                  {selectedAssetsSecond && selectedAssetsSecond.map((asset, index) =>
                    <div className="asset-item" key={index}>
                      {asset.data?.img?.startsWith('Qm') ? (
                        <img
                          src={`https://atomichub-ipfs.com/ipfs/${asset.data?.img}`}
                          alt=""
                          className='asset-item_img'
                        />
                      ) : (
                        <img src={asset.data?.img} alt="" />
                      )}
                      <p className='asset-item_id'>#{asset.asset_id}</p>
                      <p className='asset-item_name'>{asset.name}</p>
                    </div>
                  )}
                </div>
              </div>

              <div className="cost">
                Will cost: <span>
                  {selectedAssetsSecond.length > 0
                    ? `${selectedAssetsSecond.length} NFTs`
                    : '0 NFTs'}
                  {currentPool.additional_price !== '0.00000000 WAX'
                    ? ` + ${currentPool.additional_price}`
                    : ''}
                </span>
              </div>
            </div>
          )}
        </div>
      )}

      {!loading && (
        <NavigationButtonsOfferPool
          currentStep={currentStep}
          handleBackClick={handleBackClick}
          handleNextClick={handleNextClick}
          selectedAssetsFirst={selectedAssetsFirst}
          selectedAssetsSecond={selectedAssetsSecond}
          handleCancel={handleCancel}
          confirmSwapOfferPool={confirmSwapOfferPool}
          currentPool={currentPool}
        />
      )}

    </div>
  );
};

export default SwapOfferPage;
