import axios from 'axios';
import {
  ATOMIC_ASSETS,
  ATOMIC_ASSETS_API,
  EOSIO_TOKEN,
  TOKEN_CONTRACT,
  WASWASWASWAS_CONTRACT,
  WUFFI_TOKEN,
} from '../constants/wax.constants';
import {
  fetchRows,
  getDataFromAtomicApi,
  signTransaction,
  signTransactions,
} from '../helpers';

// Get balance ALCH
export const getBalance = async ({ user }) => {
  const { rows } = await fetchRows({
    contract: TOKEN_CONTRACT,
    scope: user,
    table: 'accounts',
    limit: 100,
  });

  if (rows[0]?.balance) {
    return rows[0].balance;
  } else {
    return '0 ALCH';
  }
};

// Get balance WUF
export const getWufBalance = async ({ user }) => {
  const { rows } = await fetchRows({
    contract: WUFFI_TOKEN,
    scope: user.accountName,
    table: 'accounts',
    limit: 100,
  });

  if (rows[0]?.balance) {
    return rows[0].balance;
  } else {
    return '0 WUF';
  }
};

// Get balance WAX
export const getWaxBalance = async ({ activeUser }) => {
  const { rows } = await fetchRows({
    contract: EOSIO_TOKEN,
    scope: activeUser.accountName,
    table: 'accounts',
    limit: 100,
  });

  if (rows[0]?.balance) {
    return rows[0].balance;
  } else {
    return '0 WAX';
  }
};

// Get balance ALCH
export const ALCHInfo = async () => {
  const { rows } = await fetchRows({
    contract: TOKEN_CONTRACT,
    scope: 'ALCH',
    table: 'stat',
    limit: 100,
  });

  return { current: rows[0].supply, total: rows[0].max_supply };
};

export const getUserReward = async ({ activeUser }) => {
  try {
    const { rows } = await fetchRows({
      contract: WASWASWASWAS_CONTRACT,
      scope: activeUser.accountName,
      table: 'collectedrew',
      limit: 100,
    });

    return rows[0]?.collected_rewards || '0';
  } catch (error) {
    console.error('Error fetching user reward:', error);
    return '0';
  }
};

export const getStakedTokens = async ({ activeUser }) => {
  const { rows } = await fetchRows({
    contract: WASWASWASWAS_CONTRACT,
    scope: WASWASWASWAS_CONTRACT,
    table: 'staked',
    limit: 1000,
  });

  const filteredRows = rows.filter(
    (row) => row.owner === activeUser.accountName
  );

  let totalStakedTokens = 0;

  rows.forEach((row) => {
    const stakedTokensValue = parseFloat(row.staked_tokens.split(' ')[0]);
    totalStakedTokens += stakedTokensValue;
  });

  if (filteredRows) {
    return filteredRows.map((row) => ({
      staked_tokens: row.staked_tokens,
      total_staked_tokens: `${totalStakedTokens.toFixed(4)} ALCH`,
      offer_pools: row.offer_pools,
    }));
  } else {
    return filteredRows.map(() => ({
      staked_tokens: 0,
      total_staked_tokens: `0 ALCH`,
      offer_pools: 0,
    }));
  }
};

// Deposit to buy pool
export const depositToken = async ({ activeUser, amount, pool_id }) => {
  return await signTransaction({
    activeUser,
    account: EOSIO_TOKEN,
    action: 'transfer',
    data: {
      from: activeUser.accountName,
      to: WASWASWASWAS_CONTRACT,
      quantity: amount + '.00000000 WAX',
      memo: `deposit pool:${pool_id}`,
    },
  });
};

export const depositTokenToTrade = async ({ activeUser, amount, pool_id }) => {
  return await signTransaction({
    activeUser,
    account: EOSIO_TOKEN,
    action: 'transfer',
    data: {
      from: activeUser.accountName,
      to: WASWASWASWAS_CONTRACT,
      quantity: amount + '.00000000 WAX',
      memo: `deposit trade pool:${pool_id}`,
    },
  });
};

export const withdrowToken = async ({ activeUser, amount, pool_id }) => {
  return await signTransaction({
    activeUser,
    account: WASWASWASWAS_CONTRACT,
    action: 'withdrawtok',
    data: {
      pool_id: pool_id,
      amount_to_withdraw: amount + '.00000000 WAX',
      table_name: 'buypool',
    },
  });
};

export const withdrowTokenFromTrade = async ({
  activeUser,
  amount,
  pool_id,
}) => {
  return await signTransaction({
    activeUser,
    account: WASWASWASWAS_CONTRACT,
    action: 'withdrawtok',
    data: {
      pool_id: pool_id,
      amount_to_withdraw: amount + '.00000000 WAX',
      table_name: 'tradepool',
    },
  });
};

export const withdrawNfts = async ({
  activeUser,
  selectedItemToWithdraw,
  id,
}) => {
  return await signTransaction({
    activeUser,
    account: WASWASWASWAS_CONTRACT,
    action: 'nftswithdraw',
    data: {
      pool_id: id,
      NFTs_to_unstake: selectedItemToWithdraw,
      table_name: 'sellpool',
    },
  });
};

export const withdrawNftsFromTrade = async ({
  activeUser,
  selectedItemToWithdraw,
  id,
}) => {
  return await signTransaction({
    activeUser,
    account: WASWASWASWAS_CONTRACT,
    action: 'nftswithdraw',
    data: {
      pool_id: id,
      NFTs_to_unstake: selectedItemToWithdraw,
      table_name: 'tradepool',
    },
  });
};

export const depositNfts = async (activeUser, selectedNftToDeposit, id) => {
  return await signTransaction({
    activeUser,
    account: ATOMIC_ASSETS,
    action: 'transfer',
    data: {
      from: activeUser.accountName,
      to: WASWASWASWAS_CONTRACT,
      asset_ids: selectedNftToDeposit,
      memo: `deposit NFTs:${id}`,
    },
  });
};

export const depositNftsToTrade = async (
  activeUser,
  selectedNftToDeposit,
  id
) => {
  return await signTransaction({
    activeUser,
    account: ATOMIC_ASSETS,
    action: 'transfer',
    data: {
      from: activeUser.accountName,
      to: WASWASWASWAS_CONTRACT,
      asset_ids: selectedNftToDeposit,
      memo: `deposit trade NFTs:${id}`,
    },
  });
};

// Send NFT to buy pool
export const sendAssets = async (
  activeUser,
  assets,
  pool_id,
  currentLocation
) => {
  return await signTransaction({
    activeUser,
    account: ATOMIC_ASSETS,
    action: 'transfer',
    data: {
      from: activeUser.accountName,
      to: WASWASWASWAS_CONTRACT,
      asset_ids: assets,
      memo:
        currentLocation !== 'trade'
          ? `sell NFTs:${pool_id}`
          : `sell trade NFTs:${pool_id}`,
    },
  });
};

// Update Buy pool
export const updateBuyPool = async (activeUser, pool_id, newData) => {
  const {
    new_token_contract,
    new_schemas,
    new_templates,
    new_NFTs_collection,
    new_starting_floor_price,
    new_linear_delta,
    new_exponential_delta,
  } = newData;

  return await signTransaction({
    activeUser,
    account: ATOMIC_ASSETS,
    action: 'uptbuypool',
    data: {
      pool_id,
      new_token_contract,
      new_schemas,
      new_templates,
      new_NFTs_collection,
      new_starting_floor_price,
      new_linear_delta,
      new_exponential_delta,
    },
  });
};

// Create buy pool
export const buyPoolInit = async (
  activeUser,
  floor_price,
  NFTs_collection,
  schema,
  templates,
  delta,
  exponDelta,
  waxTokenConfig,
  totalPrice,
  selectedTokenToDeposit
) => {
  const [decimalPlaces, token] = waxTokenConfig.split(',').map((s) => s.trim());
  const starting_floor_price = selectedTokenToDeposit === 'WAX' ? `${Number(floor_price).toFixed(
    decimalPlaces
  )} ${token}` : `${Number(floor_price).toFixed(
    4
  )} WUF`;
  const linear_delta = delta * Math.pow(10, decimalPlaces);

  let pools = [];

  await getBuyPoolsByCollections()
    .then((data) => pools.push(...data))
    .catch((error) => console.log(error));

  const lastPool = pools[pools.length - 1];
  const nextPoolId = lastPool ? lastPool.pool_id + 1 : 1;

  const actions = [
    {
      account: WASWASWASWAS_CONTRACT,
      action: 'initbuypool',
      data: {
        pool_owner: activeUser.accountName,
        token_contract: selectedTokenToDeposit === 'WAX' ? EOSIO_TOKEN : WUFFI_TOKEN,
        schemas: schema.length ? [schema] : [],
        templates: templates,
        NFTs_collection: NFTs_collection,
        starting_floor_price: starting_floor_price,
        linear_delta: linear_delta,
        exponential_delta: exponDelta,
      },
    },
    {
      account: selectedTokenToDeposit === 'WAX' ? EOSIO_TOKEN : WUFFI_TOKEN,
      action: 'transfer',
      data: {
        from: activeUser.accountName,
        to: WASWASWASWAS_CONTRACT,
        quantity: selectedTokenToDeposit === 'WAX' ? `${Number(totalPrice).toFixed(decimalPlaces)} ${token}` : `${Number(totalPrice).toFixed(4)} WUF`,
        memo: `deposit pool:` + Number(nextPoolId),
      },
    },
  ];

  return await signTransactions({
    activeUser,
    actions,
  });
};

// Create sell pool
export const sellPoolInit = async (
  activeUser,
  floor_price,
  NFTs_collection,
  templates,
  delta,
  exponDelta,
  waxTokenConfig,
  selectedItemFromSell,
  selectedCollectionForSellPool,
  selectedSchemaForSellPool,
  selectedTokenToDeposit
) => {
  const [decimalPlaces, token] = waxTokenConfig.split(',').map((s) => s.trim());
  const starting_floor_price = selectedTokenToDeposit === 'WAX' ? `${Number(floor_price).toFixed(
    decimalPlaces
  )} ${token}` : `${Number(floor_price).toFixed(4)} WUF`;
  const linear_delta = selectedTokenToDeposit === 'WAX' ? delta * Math.pow(10, decimalPlaces) : delta * Math.pow(10, 4);

  let pools = [];

  await getSellPoolsByCollections()
    .then((data) => pools.push(...data))
    .catch((error) => console.log(error));

  const lastPool = pools[pools.length - 1];
  const nextPoolId = lastPool ? lastPool.pool_id + 1 : 1;

  const actions = [
    {
      account: WASWASWASWAS_CONTRACT,
      action: 'initsellpool',
      data: {
        pool_owner: activeUser.accountName,
        token_contract: selectedTokenToDeposit === 'WAX' ? EOSIO_TOKEN : WUFFI_TOKEN,
        schemas: selectedSchemaForSellPool ? [selectedSchemaForSellPool] : [],
        templates: templates,
        NFTs_collection: selectedCollectionForSellPool,
        starting_floor_price: starting_floor_price,
        linear_delta: linear_delta,
        exponential_delta: exponDelta,
      },
    },
    {
      account: ATOMIC_ASSETS,
      action: 'transfer',
      data: {
        from: activeUser.accountName,
        to: WASWASWASWAS_CONTRACT,
        asset_ids: selectedItemFromSell.map((item) => item.asset_id),
        memo: `deposit NFTs:` + nextPoolId,
      },
    },
  ];

  return await signTransactions({
    activeUser,
    actions,
  });
};

export const tradePoolInit = async (
  activeUser,
  floor_price,
  NFTs_collection,
  templates,
  delta,
  exponDelta,
  waxTokenConfig,
  selectedNftsForDepositTrade,
  schema,
  fees,
  totalTradeToEarn,
  selectedTokenToDeposit
) => {
  const [decimalPlaces, token] = waxTokenConfig.split(',').map((s) => s.trim());
  const starting_floor_price = selectedTokenToDeposit === 'WAX' ? `${Number(floor_price).toFixed(
    decimalPlaces
  )} ${token}` : `${Number(floor_price).toFixed(4)} WUF`;
  const linear_delta = selectedTokenToDeposit === 'WAX' ? delta * Math.pow(10, decimalPlaces) : delta * Math.pow(10, 4);

  let pools = [];

  await getTradePoolsByCollections()
    .then((data) => pools.push(...data))
    .catch((error) => console.log(error));

  const lastPool = pools[pools.length - 1];
  const nextPoolId = lastPool ? lastPool.pool_id + 1 : 1;

  const actions = [
    {
      account: WASWASWASWAS_CONTRACT,
      action: 'trdpoolinit',
      data: {
        pool_owner: activeUser.accountName,
        NFTs_collection: NFTs_collection.collection_name,
        token_contract: selectedTokenToDeposit === 'WAX' ? EOSIO_TOKEN : WUFFI_TOKEN,
        schemas: schema.length ? [schema] : [],
        templates: templates,
        starting_floor_price: starting_floor_price,
        linear_delta: linear_delta,
        exponential_delta: exponDelta,
        pool_fees: fees,
      },
    },
    {
      account: ATOMIC_ASSETS,
      action: 'transfer',
      data: {
        from: activeUser.accountName,
        to: WASWASWASWAS_CONTRACT,
        asset_ids: selectedNftsForDepositTrade.map((item) => item.asset_id),
        memo: `deposit trade NFTs:` + nextPoolId,
      },
    },
    {
      account: selectedTokenToDeposit === 'WAX' ? EOSIO_TOKEN : WUFFI_TOKEN,
      action: 'transfer',
      data: {
        from: activeUser.accountName,
        to: WASWASWASWAS_CONTRACT,
        quantity: selectedTokenToDeposit === 'WAX' ? `${Number(totalTradeToEarn).toFixed(decimalPlaces)} ${token}` : `${Number(totalTradeToEarn).toFixed(4)} WUF`,
        memo: `deposit trade pool:` + Number(nextPoolId),
      },
    },
  ];

  return await signTransactions({
    activeUser,
    actions,
  });
};

export const offerPoolInit = async (
  activeUser,
  NFTs_collection,
  user_required_templates,
  templates,
  schema,
  whitelisted_accounts,

  asset_ids,
  selectedAmountSwapPool,

  additional_price
) => {
  
  let pools = [];

  await getOfferPoolsByCollections()
    .then((data) => pools.push(...data))
    .catch((error) => console.log(error));

  const lastPool = pools[pools.length - 1];
  const nextPoolId = lastPool ? lastPool.pool_id + 1 : 1;
  const templateID = templates.map(template => template.template_id)
  const ownerReceivingAmount = user_required_templates.reduce((sum, template) => sum + template.value, 0);


  const actions = [
    {
      account: WASWASWASWAS_CONTRACT,
      action: 'initoffer',
      data: {
        pool_owner: activeUser.accountName,
        NFTs_collection: NFTs_collection?.collection_name,
        user_required_templates: user_required_templates,
        templates: templateID,
        schemas: schema.length ? [schema] : [],
        whitelisted_accounts: whitelisted_accounts,
        user_receiving_amount: selectedAmountSwapPool,
        owner_receiving_amount: ownerReceivingAmount
      },
    },
    {
      account: ATOMIC_ASSETS,
      action: 'transfer',
      data: {
        from: activeUser.accountName,
        to: WASWASWASWAS_CONTRACT,
        asset_ids: asset_ids.length ? asset_ids.map(asset => asset.asset_id) : [],
        memo: `deposit offer NFTs:` + nextPoolId,
      },
    },
  ];

  if (additional_price > 0) {
    actions.push({
      account: WASWASWASWAS_CONTRACT,
      action: 'setprice',
      data: {
        pool_id: nextPoolId,
        additional_price: `${Number(additional_price).toFixed(8)} WAX`,
        token_contract: 'eosio.token',
      },
    });
  }

  return await signTransactions({
    activeUser,
    actions,
  });
};

// Buy NFTs from Sell pool
export const BuyNfts = async (
  activeUser,
  poolId,
  amount,
  waxTokenConfig,
  selectedAssets,
  currentLocation,
  token_contract
) => {
  const [decimalPlaces, token] = waxTokenConfig.split(',').map((s) => s.trim());

  const actions = [
    {
      account: token_contract === EOSIO_TOKEN ? EOSIO_TOKEN : WUFFI_TOKEN,
      action: 'transfer',
      data: {
        from: activeUser.accountName,
        to: WASWASWASWAS_CONTRACT,
        quantity: `${Number(amount).toFixed(decimalPlaces)} ${token}`,
        memo: 'deposit tokens',
      },
    },
    {
      account: WASWASWASWAS_CONTRACT,
      action: currentLocation !== 'trade' ? 'buynfts' : 'tradebuynfts',
      data: {
        buyer: activeUser.accountName,
        pool_id: poolId,
        nfts_to_buy: selectedAssets,
      },
    },
  ];

  return await signTransactions({
    activeUser,
    actions,
  });
};

// Get all collections from Atomic Hub
// export async function getCollections() {
//     let currentPage = 1;
//     let result = [];
//
//     while (true) {
//         const { data } = await getDataFromAtomicApi(`collections?page=${currentPage}&limit=1000`);
//
//         if (data.data.length) {
//             result.push(...data.data);
//             currentPage++;
//         } else {
//             break;
//         }
//
//         await new Promise(resolve => setTimeout(resolve, 400)); // Wait for 1 second before making the next request
//     }
//
//     return result;
// }

// Chank
function chunkArray(array, chunkSize) {
  const chunks = [];
  let index = 0;

  while (index < array.length) {
    chunks.push(array.slice(index, index + chunkSize));
    index += chunkSize;
  }

  return chunks;
}

export async function stake({ activeUser, amount }) {
  return await signTransaction({
    activeUser,
    account: TOKEN_CONTRACT,
    action: 'transfer',
    data: {
      from: activeUser.accountName,
      to: WASWASWASWAS_CONTRACT,
      quantity: `${Number(amount).toFixed(4)} ALCH`,
      memo: 'stake',
    },
  });
}

export async function unstake({ activeUser, amount }) {
  return await signTransaction({
    activeUser,
    account: WASWASWASWAS_CONTRACT,
    action: 'withdrawstak',
    data: {
      owner: activeUser.accountName,
      quantity: `${Number(amount).toFixed(4)} ALCH`,
    },
  });
}

export async function claimToken({ activeUser }) {
  return await signTransaction({
    activeUser,
    account: WASWASWASWAS_CONTRACT,
    action: 'withdrawrew',
    data: {
      user: activeUser.accountName,
    },
  });
}

export const acceptOffer = async ({ activeUser, id, selectedAssetsFirst, selectedAssetsSecond, currentPool }) => {
  const actions = [];

  if (Number(currentPool.additional_price.split(' ')[0]) > 0) {
    actions.push({
      account: EOSIO_TOKEN,
      action: 'transfer',
      data: {
        from: activeUser.accountName,
        to: WASWASWASWAS_CONTRACT,
        quantity: currentPool.additional_price,
        memo: 'deposit tokens',
      },
    });
  }

  actions.push({
    account: ATOMIC_ASSETS,
    action: 'transfer',
    data: {
      from: activeUser.accountName,
      to: WASWASWASWAS_CONTRACT,
      asset_ids: selectedAssetsSecond.map(asset => asset.asset_id),
      memo: 'deposit temporary offer',
    },
  });

  actions.push({
    account: WASWASWASWAS_CONTRACT,
    action: 'acceptoffer',
    data: {
      user: activeUser.accountName,
      pool_id: Number(id),
      NFTs_to_give: selectedAssetsSecond.map(asset => asset.asset_id),
      NFTs_to_receive: selectedAssetsFirst.map(asset => asset.asset_id),
    },
  });

  return await signTransactions({ activeUser, actions });
};


// Get all WL collections from Atomic Hub
export async function getWLCollections() {
  const { rows } = await fetchRows({
    contract: 'atomhubtools',
    scope: 'atomhubtools',
    table: 'acclists',
    limit: 1,
    lowerBound: 'col.wlist',
    upperBound: 'col.wlist',
  });

  if (rows[0]) {
    const collectionNames = rows[0].list;
    const collectionChunks = chunkArray(collectionNames, 600);


    const results = [];
  

    for (const chunk of collectionChunks) {
      const collectionNamesChunk = chunk.join(',');

      const { data } = await axios.get(
        `https://test.wax.api.atomicassets.io/atomicmarket/v1/stats/collections`,
        {
          params: {
            symbol: 'WAX',
            page: 1,
            limit: 100,
            order: 'desc',
            collection_whitelist: collectionNamesChunk,
          },
        }
      );

      if (data && data.data && data.data.results) {
        const chunkResults = data.data.results;
        results.push(...chunkResults);
      }
    }
    return results;
  } else {
    return [];
  }
}

export async function getCollectionsSearch(searchTerm) {
  let result = [];

  const { data } = await axios.get(
    `${ATOMIC_ASSETS_API}/collections?match=${searchTerm}&limit=1000`
  );
  if (data.data.length) {
    const filteredData = data.data.filter((collection) =>
      collection.collection_name.includes(searchTerm)
    );
    result.push(...filteredData);
  }

  await new Promise((resolve) => setTimeout(resolve, 1000));

  return result;
}

export async function getCollectionByName(name) {
  const { data } = await axios.get(
    `https://wax.api.atomicassets.io/atomicmarket/v1/stats/collections/${name}?symbol=WAX`
  );
  return data.data.result;
}

export async function getUserCollections(activeUser) {
  const response = await axios.get(
    `${ATOMIC_ASSETS_API}/accounts/${activeUser.accountName}?hide_offers=false`
  );
  const collections = response.data?.data?.collections || [];

  const collectionNames = collections.map(({ collection }) => collection);
  return collectionNames;
}

export async function getCollectionSchema(collection) {
  const { data } = await axios.get(
    `${ATOMIC_ASSETS_API}/schemas?collection_name=${collection}&page=1&limit=100`
  );
  return data.data;
}

export async function getTemplate(collection, schema) {
  const { data } = await axios.get(
    `${ATOMIC_ASSETS_API}/templates?collection_name=${collection}&schema_name=${schema}&page=1&limit=100`
  );
  return data.data;
}

export async function getMyNFTs(owner, collectionName) {
  console.log(collectionName);
  if (owner) {
    const { data } = await axios.get(
      `${ATOMIC_ASSETS_API}/assets?owner=${owner}&collection_name=${collectionName}&page=1&limit=100&order=desc&sort=asset_id`
    );
    return data.data;
  }
}

export async function getMyAllNFTs(owner) {
  if (owner) {
    return await getDataFromAtomicApi(
      `assets?owner=${owner}&page=1&limit=100&order=desc&sort=asset_id`
    );
  }
}

export const getSellPoolsByCollections = async () => {
  const { rows } = await fetchRows({
    contract: WASWASWASWAS_CONTRACT,
    scope: WASWASWASWAS_CONTRACT,
    table: 'sellpool',
    limit: 100,
  });

  const uniqueCollections = [];
  const uniqueIDs = [];

  for (const row of rows) {
    const collection = row.NFTs_collection;
    if (!uniqueCollections.includes(collection)) {
      uniqueCollections.push(collection);
    }

    const idsArr = row.pool_nfts;

    for (const id of idsArr) {
      uniqueIDs.push(id);
    }
  }

  const collectionsQuery = uniqueCollections.join(',');
  const IDsQuery = uniqueIDs.join(',');

  const IDs = IDsQuery.split(',');

  const batchSize = 50;
  const batches = [];

  while (IDs.length > 0) {
    batches.push(IDs.splice(0, batchSize));
  }

  const responses = [];

  for (const batch of batches) {
    const batchIDsQuery = batch.join(',');
    const response = await axios.get(
      `${ATOMIC_ASSETS_API}/assets?ids=${batchIDsQuery}`
    );
    responses.push(response.data.data);
  }

  const mergedResponse = [].concat(...responses);

  const { data: collections } = await axios.get(
    `${ATOMIC_ASSETS_API}/collections?collection_whitelist=${collectionsQuery}`
  );

  const newRows = rows.map((row) => {
    const collectionData = collections.data.find((d) => {
      return d.collection_name === row.NFTs_collection;
    });

    const IDsData = mergedResponse.filter((d) => {
      return row.pool_nfts.includes(d.asset_id);
    });

    return {
      ...row,
      NFTs_collection: collectionData ? collectionData : row.NFTs_collection,
      pool_nfts: IDsData,
    };
  });

  return newRows;
};

export const getOfferPoolsByCollections = async () => {
  const { rows } = await fetchRows({
    contract: WASWASWASWAS_CONTRACT,
    scope: WASWASWASWAS_CONTRACT,
    table: 'offerpool',
    limit: 100,
  });


  const uniqueCollections = [];
  const uniqueIDs = [];

  for (const row of rows) {
    const collection = row.NFTs_collection;
    if (!uniqueCollections.includes(collection)) {
      uniqueCollections.push(collection);
    }


    const idsArr = row.pool_nfts;

    for (const id of idsArr) {
      uniqueIDs.push(id);
    }
  }

  const IDsQuery = uniqueIDs.join(',');


  const collectionsQuery = uniqueCollections.join(',');
  const IDs = IDsQuery.split(',');

  const batchSize = 50;
  const batches = [];

  while (IDs.length > 0) {
    batches.push(IDs.splice(0, batchSize));
  }

  const responses = [];

  for (const batch of batches) {
    const batchIDsQuery = batch.join(',');
    const response = await axios.get(
      `${ATOMIC_ASSETS_API}/assets?ids=${batchIDsQuery}`
    );
    responses.push(response.data.data);
  }

  const mergedResponse = [].concat(...responses);

  const { data: collections } = await axios.get(
    `${ATOMIC_ASSETS_API}/collections?collection_whitelist=${collectionsQuery}`
  );

  const newRows = rows.map((row) => {
    const collectionData = collections.data.find((d) => {
      return d.collection_name === row.NFTs_collection;
    });

    const IDsData = mergedResponse.filter((d) => {
      return row.pool_nfts.includes(d.asset_id);
    });

    return {
      ...row,
      NFTs_collection: collectionData ? collectionData : row.NFTs_collection,
      pool_nfts: IDsData,
    };
  });

  return newRows;
};

export const getTradePoolsByCollections = async () => {
  const { rows } = await fetchRows({
    contract: WASWASWASWAS_CONTRACT,
    scope: WASWASWASWAS_CONTRACT,
    table: 'tradepool',
    limit: 100,
  });

  const uniqueCollections = [];
  const uniqueIDs = [];

  for (const row of rows) {
    const collection = row.NFTs_collection;
    if (!uniqueCollections.includes(collection)) {
      uniqueCollections.push(collection);
    }

    const idsArr = row.pool_nfts;

    for (const id of idsArr) {
      uniqueIDs.push(id);
    }
  }

  const collectionsQuery = uniqueCollections.join(',');
  const IDsQuery = uniqueIDs.join(',');

  const IDs = IDsQuery.split(',');

  const batchSize = 50; // Кількість значень, яку ви хочете передати в кожному запиті
  const batches = [];

  while (IDs.length > 0) {
    batches.push(IDs.splice(0, batchSize));
  }

  const responses = [];

  for (const batch of batches) {
    const batchIDsQuery = batch.join(',');
    const response = await axios.get(
      `${ATOMIC_ASSETS_API}/assets?ids=${batchIDsQuery}`
    );
    responses.push(response.data.data);
  }

  const mergedResponse = [].concat(...responses);

  const { data: collections } = await axios.get(
    `${ATOMIC_ASSETS_API}/collections?collection_whitelist=${collectionsQuery}`
  );

  // const { data: ids } = await axios.get(
  //     `https://wax.api.atomicassets.io/atomicassets/v1/assets?ids=${IDsQuery}`
  // );

  const newRows = rows.map((row) => {
    const collectionData = collections.data.find((d) => {
      return d.collection_name === row.NFTs_collection;
    });

    const IDsData = mergedResponse.filter((d) => {
      return row.pool_nfts.includes(d.asset_id);
    });

    return {
      ...row,
      NFTs_collection: collectionData ? collectionData : row.NFTs_collection,
      pool_nfts: IDsData,
    };
  });

  return newRows;
};

// Get main config
export const getMainConfig = async () => {
  const { rows } = await fetchRows({
    contract: WASWASWASWAS_CONTRACT,
    scope: WASWASWASWAS_CONTRACT,
    table: 'mainconf',
    limit: 100,
  });

  return rows[0];
};

// Get fees config
export const getFeesConfig = async () => {
  const { rows } = await fetchRows({
    contract: WASWASWASWAS_CONTRACT,
    scope: WASWASWASWAS_CONTRACT,
    table: 'feesconfig',
    limit: 100,
  });

  return rows;
};

export const getBuyPoolsByCollections = async () => {
  const { rows } = await fetchRows({
    contract: WASWASWASWAS_CONTRACT,
    scope: WASWASWASWAS_CONTRACT,
    table: 'buypool',
    limit: 100,
  });

  const uniqueCollections = [];

  for (const row of rows) {
    const collection = row.NFTs_collection;
    if (!uniqueCollections.includes(collection)) {
      uniqueCollections.push(collection);
    }
  }

  const collectionsQuery = uniqueCollections.join(',');

  const { data } = await axios.get(
    `${ATOMIC_ASSETS_API}/collections?collection_whitelist=${collectionsQuery}`
  );

  const newRows = rows.map((row) => {
    const collectionData = data.data.find((d) => {
      return d.collection_name === row.NFTs_collection;
    });

    return {
      ...row,
      NFTs_collection: collectionData ? collectionData : row.NFTs_collection,
    };
  });

  return newRows;
};

export const getNftsByIds = async (asset_ids) => {
  if (!Array.isArray(asset_ids)) {
    throw new Error('asset_ids must be an array');
  }

  return asset_ids;
};
