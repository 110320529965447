import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import FilteredTable from '../../components/FilteredTable/FilteredTable';
import LoaderData from '../../components/LoaderData/LoaderData';

import {
  getBuyPoolsByCollections,
  getSellPoolsByCollections,
  getTradePoolsByCollections,
  getOfferPoolsByCollections,
} from '../../services/wax.services';

import './CollectionsPage.scss';

const CollectionsPage = () => {
  const allCollections = useSelector((state) => state.allCollections);
  const [allPools, setAllPools] = useState([]);

  useEffect(() => {
    Promise.all([
      getBuyPoolsByCollections(),
      getSellPoolsByCollections(),
      getTradePoolsByCollections(),
      getOfferPoolsByCollections(),
    ])
      .then(([buyData, sellData, tradeData, offerData]) => {
        const combinedData = [...buyData, ...sellData, ...tradeData, ...offerData];
        setAllPools(combinedData);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <>
      <div className="collections-page-title">
        <h2>Collections</h2>
      </div>
      <div className="collections-page-table">
        {!allCollections.length ? (
          <LoaderData />
        ) : (
          <FilteredTable allCollections={allCollections} allPools={allPools} />
        )}
      </div>
    </>
  );
};

export default CollectionsPage;
