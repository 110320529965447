import React, { useEffect, useState, useContext } from 'react';

import { useSelector } from 'react-redux';
import OrangeBorderButton from '../../components/OrangeBorderButton/OrangeBorderButton';

import {
  getCollectionSchema,
  getCollectionsSearch,
  getFeesConfig,
  getMyAllNFTs,
  getTemplate,
  offerPoolInit,
} from '../../services/wax.services';

import FirstStep from '../../components/CreatePoolSteps/FirstStep/FirstStep';
import SecondStep from '../../components/CreatePoolSteps/SecondStep/SecondStep';
import ThirdStep from '../../components/CreatePoolSteps/ThirdStep/ThirdStep';
import FourthStep from '../../components/CreatePoolSteps/FourthStep/FourthStep';
import RodalCreatePool from '../../components/Modal/RodalCreatePool/RodalCreatePool';

import { BOTH, BUY, OFFER, SELL } from '../../constants/pool.constants';
import { EOSIO_TOKEN } from '../../constants/wax.constants';
import { UALContext } from 'ual-reactjs-renderer';


import RodalDepositOfferPool from '../../components/Modal/RodalDepositOfferPool/RodalDepositOfferPool';
import { toast } from 'react-toastify';
import { toastOptions } from '../../helpers/toast.helper';
import { useNavigate } from 'react-router-dom';

import './CreatePool.scss';

const CreatePool = () => {
  const navigate = useNavigate();
  const { activeUser } = useContext(UALContext);
  const allUserCollections = useSelector((state) => state.allUserCollections);
  const [filteredAllCollections, setFilteredAllCollections] = useState([]);

  const [currentSchema, setCurrentSchema] = useState([]);
  const [currentTemplate, setCurrentTemplate] = useState([]);
  const [currentSwapTemplate, setCurrentSwapTemplate] = useState([]);
  const [currentReceiveTemplate, setCurrentReceiveTemplate] = useState([]);
  const [priceTotal, setPriceTotal] = useState(0);

  const [currentStep, setCurrentStep] = useState(1);
  const [currentStepName, setCurrentStepName] = useState('Selecting pool type');
  const [selectedOption, setSelectedOption] = useState(null);
  const [visible, setVisible] = useState(false);
  const [visibleOffer, setVisibleOffer] = useState(false);
  const [openList, setOpenList] = useState(false);
  const [openListForSwap, setOpenListForSwap] = useState(false);
  const [openListForSchema, setOpenListForSchema] = useState(false);
  const [openListForTemplate, setOpenListForTemplate] = useState(false);
  const [openListForSwapTemplate, setOpenListForSwapTemplate] = useState(false);
  
  const [openListForReceiveTemplate, setOpenListForReceiveTemplate] = useState(false);

  const [searchCollectionValue, setSearchCollectionValue] = useState('');
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [selectedCollectionForSwapPool, setSelectedCollectionForSwapPool] = useState(null);
  const [selectedSchema, setSelectedSchema] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedSwapTemplate, setSelectedSwapTemplate] = useState([]);
  const [selectedReceiveTemplate, setSelectedReceiveTemplate] = useState([]);
  const [selectedSchemaForSwapOfferPool, setSelectedSchemaForSwapOfferPool] = useState(null);

  const [startPrice, setStartPrice] = useState(0);
  const [delta, setDelta] = useState(0);
  const [exponDelta, setExponDelta] = useState(0);
  const [amount, setAmount] = useState(0);

  const [amountToBuy, setAmountToBuy] = useState(0);
  const [amountToSell, setAmountToSell] = useState(0);

  const [slideValue, setSliderValue] = useState(0);
  const [slideValueToSell, setSliderValueToSell] = useState(0);

  const [price, setPrice] = useState(0);
  const [priceExpon, setPriceExpon] = useState(0);
  const [priceExponTotal, setPriceExponTotal] = useState(0);

  const [addedCollections, setAddedCollections] = useState([]);

  const [waxTokenConfig, setWaxTokenConfig] = useState('');

  const [myNFTs, setMyNFTs] = useState([]);

  const [selectedItemFromSell, setSelectedItemFromSell] = useState([]);

  const [totalPrice, setTotalPrice] = useState(0);

  const [openTokenListForBuy, setOpenTokenListForBuy] = useState(false);
  const [openTokenListForSell, setOpenTokenListForSell] = useState(false);
  const [openTokenListForTrade, setOpenTokenListForTrade] = useState(false);

  const [selectedTokenToDeposit, setSelectedTokenToDeposit] = useState('WAX');


  // for create sell pool
  const [selectedCollectionForSellPool, setSelectedCollectionForSellPool] =
    useState(null);
  const [selectedSchemaForSellPool, setSelectedSchemaForSellPool] =
    useState(null);
  const [openListForSellSchema, setOpenListForSellSchema] = useState(false);
  const [openListForSwapSchema, setOpenListForSwapSchema] = useState(false);

  const [currentSchemaForSell, setCurrentSchemaForSell] = useState([]);
  const [currentSchemaForSwap, setCurrentSchemaForSwap] = useState([]);

  const [feesAmountForTrade, setFeesAmountForTrade] = useState(0);

  const [willCost, setWillCost] = useState([]);
  const [willEarn, setWillEarn] = useState([]);
  const [willCostSum, setWillCostSum] = useState(0);
  const [willEarnSum, setWillEarnSum] = useState(0);

  const [totalTradeToCost, setTotalTradeToCost] = useState(0);
  const [totalTradeToEarn, setTotalTradeToEarn] = useState(0);

  const [visibility, setVisibility] = useState('Public');
  const [verifiedAccounts, setVerifiedAccounts] = useState([]);

  const [selectedNftToDeposit, setSelectedNftToDeposit] = useState([]);
  const [selectedAmountSwapPool, setSelectedAmountSwapPool] = useState(1);

  const [additionalPriceOffrePool, setAdditionalPriceOffrePool] = useState(0);


  useEffect(() => {
    let sum =
      slideValueToSell === 0
        ? 0
        : willEarn
          .slice(0, slideValueToSell)
          .reduce((acc, val) => acc + val, 0);
    setWillCostSum(sum);
  }, [willEarn, slideValueToSell]);

  useEffect(() => {
    // let sum = slideValue === 0 ? 0 : willCost.slice(0, slideValue).reduce((acc, val) => acc + val, 0);
    let sum =
      slideValue === 0
        ? 0
        : willCost.slice(-slideValue).reduce((acc, val) => acc + val, 0);
    setWillEarnSum(sum);
  }, [willCost, slideValue]);

  useEffect(() => {
    let sum =
      amountToBuy === 0
        ? 0
        : willCost.slice(0, amountToBuy).reduce((acc, val) => acc + val, 0);
    setTotalTradeToEarn(sum);
  }, [willCost, amountToBuy]);

  useEffect(() => {
    let sum =
      amountToSell === 0
        ? 0
        : willEarn.slice(0, amountToSell).reduce((acc, val) => acc + val, 0);
    setTotalTradeToCost(sum);
  }, [willCost, amountToSell, willEarn]);

  const handleSelectCollectionForSellPool = (item) => {
    setSelectedCollectionForSellPool(item);

    setSelectedItemFromSell([]);
    setSelectedSchemaForSellPool(null);
    setOpenListForSellSchema(false);
    handlerOpenList(false);
  };

  const handleSelectItemForDepositOfferPool = (item) => {
    if (selectedNftToDeposit.includes(item)) {
      setSelectedNftToDeposit((prevSelectedNftToDeposit) =>
        prevSelectedNftToDeposit.filter((selectedItem) => selectedItem !== item)
      );
    } else {
      setSelectedNftToDeposit((prevSelectedNftToDeposit) => [
        ...prevSelectedNftToDeposit,
        item,
      ]);
    }
  };

  const handlerSelectedSchemaForSell = (item) => {
    setSelectedSchemaForSellPool(item);

    setOpenListForSellSchema(false);
  };

  const handlerSelectedSchemaForSwapOfferPool = (item) => {
    setSelectedSchemaForSwapOfferPool(item);

    setOpenListForSwapSchema(false);
  };

  const handlerOpenListForSellSchema = () => {
    setOpenListForSellSchema(!openListForSellSchema);
    
    setOpenList(false)
  };

  const handlerOpenListForSwapSchema = () => {
    setOpenListForSwapSchema(!openListForSwapSchema);
    
    setOpenListForSwap(false)
    setSelectedSwapTemplate([])
  };

  useEffect(() => {
    if (selectedCollectionForSellPool)
      getCollectionSchema(selectedCollectionForSellPool.collection_name)
        .then((data) => {
          setCurrentSchemaForSell(data);
        })
        .catch((error) => {
          console.log(error);
        });
  }, [selectedCollectionForSellPool]);

  useEffect(() => {
    if (selectedCollectionForSwapPool)
      getCollectionSchema(selectedCollectionForSwapPool.collection_name)
        .then((data) => {
          setCurrentSchemaForSwap(data);
        })
        .catch((error) => {
          console.log(error);
        });
  }, [selectedCollectionForSwapPool]);

  useEffect(() => {
    let floorPrice = Number(startPrice) || 0;

    if (slideValue === 0) {
      setPriceExpon(0);
      return;
    }

    let totalPrice = floorPrice;
    let previousPrice = floorPrice;
    for (let i = 2; i <= slideValue; i++) {
      previousPrice = previousPrice - previousPrice * (exponDelta / 100);
      totalPrice += previousPrice;
    }

    setPriceExpon(totalPrice);
  }, [slideValue, startPrice, exponDelta]);

  useEffect(() => {
    let floorPrice = Number(startPrice) || 0;

    if (amount === 0) {
      setPriceExponTotal(0);
      return;
    }

    let totalPrice = 0;
    let previousPrice = floorPrice;
    for (let i = 1; i <= amount; i++) {
      totalPrice += previousPrice;
      previousPrice = previousPrice - previousPrice * (exponDelta / 100);
    }

    setPriceExponTotal(totalPrice.toFixed(8));
    setTotalPrice(totalPrice);
  }, [amount, startPrice, exponDelta]);

  const handleAdd = () => {
    setAddedCollections([
      {
        img: selectedCollection?.data?.img,
        name: selectedCollection.collection_name,
        schema: selectedSchema,
        template: selectedTemplate,
      },
    ]);
    setOpenList(null);
    setOpenListForSchema(null);
    setOpenListForTemplate(null);
  };

  const deleteAddedCollection = () => {
    setAddedCollections([]);
    setSelectedCollection(null);
    setSelectedSchema(null);
    setSelectedTemplate(null);
  };

  const handleSelectOption = (optionIndex) => {
    setCurrentStep(optionIndex + 1);

    if (optionIndex + 1 === 2) {
      setCurrentStepName('Selecting Assets');
    }
  };

  useEffect(() => {
    getMyAllNFTs(activeUser?.accountName)
      .then((data) => {
        setMyNFTs(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [activeUser]);

  useEffect(() => {
    getFeesConfig()
      .then((data) => {
        const filteredData = data.filter(
          (item) => item.token_contract === EOSIO_TOKEN
        );
        setWaxTokenConfig(filteredData[0].fees[0].key);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (searchCollectionValue.length > 4) {
      getCollectionsSearch(searchCollectionValue)
        .then((data) => {
          setFilteredAllCollections(data);
        })
        .catch((e) => console.log(e));
    }
  }, [searchCollectionValue]);

  useEffect(() => {
    let price = Number(startPrice);
    let currentDelta = Number(delta);

    const calculatePrice = (deltaSign) => {
      if (slideValue === 0) {
        setPrice(0);
        return;
      }

      for (let i = 2; i <= Number(slideValue); i++) {
        price += Number(startPrice) + deltaSign * currentDelta;
        currentDelta += Number(delta);
      }

      setPrice(price);
    };

    if (selectedOption === BUY) {
      calculatePrice(-1);
    }
    if (selectedOption === SELL) {
      calculatePrice(1);
    }
  }, [startPrice, delta, amount, slideValue, selectedOption]);

  useEffect(() => {
    function calculateTotal(sign) {
      let price = Number(startPrice);
      let currentDelta = Number(delta);
      let amountLeft = Number(amount);

      let total = price;

      for (let i = 1; i < amountLeft; i++) {
        price -= currentDelta * sign;
        if (price <= 0) {
          total += 0;
          setAmount(i);
          break;
        }
        total += price;
      }

      return total.toFixed(8);
    }

    if (selectedOption === BUY) {
      const total = calculateTotal(1);
      setPriceTotal(total);
      if (+amount === 0) {
        setPriceTotal(0);
      }

      setTotalPrice(total);
    }

    if (selectedOption === SELL) {
      const total = calculateTotal(-1);
      setPriceTotal(total);

      setTotalPrice(total);
    }
  }, [startPrice, delta, amount, selectedOption]);

  useEffect(() => {
    if (selectedCollection)
      getCollectionSchema(selectedCollection?.collection_name)
        .then((data) => {
          setCurrentSchema(data);
        })
        .catch((error) => {
          console.log(error);
        });
  }, [selectedCollection]);

  useEffect(() => {
    if (selectedSchema)
      getTemplate(selectedCollection?.collection_name, selectedSchema)
        .then((data) => {
          setCurrentTemplate(data);
        })
        .catch((error) => {
          console.log(error);
        });
  }, [selectedCollection, selectedSchema]);

  useEffect(() => {
    if (selectedCollectionForSwapPool)
      getTemplate(selectedCollectionForSwapPool?.collection_name, selectedSchemaForSwapOfferPool)
        .then((data) => {
          setCurrentSwapTemplate(data);
        })
        .catch((error) => {
          console.log(error);
        });
  }, [selectedCollectionForSwapPool, selectedSchemaForSwapOfferPool]);

  useEffect(() => {
    if (selectedCollectionForSwapPool)
      getTemplate(selectedCollectionForSwapPool?.collection_name, selectedSchemaForSwapOfferPool)
        .then((data) => {
          setCurrentReceiveTemplate(data);
        })
        .catch((error) => {
          console.log(error);
        });
  }, [selectedCollectionForSwapPool, selectedSchemaForSwapOfferPool]);

  useEffect(() => {
    if (selectedSchemaForSwapOfferPool)
      getTemplate(selectedCollection?.collection_name, selectedSchemaForSwapOfferPool)
        .then((data) => {
          setCurrentTemplate(data);
        })
        .catch((error) => {
          console.log(error);
        });
  }, [selectedCollection, selectedSchemaForSwapOfferPool]);

  const show = () => {
    setVisible(true);
  };

  const hide = () => {
    setVisible(false);
  };

  const hideOffer = () => {
    setVisibleOffer(false);
  };
  const handleSelectCollection = (item) => {
    setSelectedCollection(item);
    setSelectedCollectionForSellPool(item);

    setSelectedSchema([]);
    setSelectedTemplate([]);
    setSelectedItemFromSell([]);

    setOpenList(false);
  };

  const handleSelectCollectionForSwapPool = (item) => {
    setSelectedCollectionForSwapPool(item);

    // setSelectedSchemaForwapPool([]);
    // setSelectedTemplateForwapPool([]);

    setOpenListForSwap(false);
  };

  const handleSelectMyCollection = () => {
    // setSelectedMyCollection(item);

    setSelectedSchema([]);
    setSelectedTemplate([]);
    setSelectedItemFromSell([]);

    setOpenList(false);
  };

  const handleSelectSchema = (item) => {
    setSelectedSchema(item);
    setOpenListForSchema(null);

    if (selectedSchema.length === 0) {
      setSelectedTemplate([]);
    }
  };

  const handleSelectedTocenToDeposit = (token) => {
    setSelectedTokenToDeposit(token);
    setOpenTokenListForBuy(false);
    setOpenTokenListForSell(false);
    setOpenTokenListForTrade(false);
  }

  const handleSelectTemplate = (item) => {
    const index = selectedTemplate.indexOf(item);

    if (index === -1) {
      setSelectedTemplate([...selectedTemplate, item]);
    } else {
      const updatedTemplate = [...selectedTemplate];
      updatedTemplate.splice(index, 1);
      setSelectedTemplate(updatedTemplate);
    }
  };

  const handleSelectSwapTemplate = (item) => {
    // Check if item is already in the selectedSwapTemplate array
    const index = selectedSwapTemplate.findIndex((template) => template.template_id === item.template_id);

    if (index === -1) {
        // If item is not in the array, add it with value 1
        const updatedSwapTemplate = [...selectedSwapTemplate, item];
        setSelectedSwapTemplate(updatedSwapTemplate);
    } else {
        // If item is already in the array, remove it
        const updatedSwapTemplate = selectedSwapTemplate.filter((template) => template.template_id !== item.template_id);
        setSelectedSwapTemplate(updatedSwapTemplate);
    }
};

const handleSelectReceiveTemplate = (item, amount) => {
  const index = selectedReceiveTemplate.findIndex((template) => template.key === item);

  if (index === -1) {
      const updatedReceiveTemplate = [...selectedReceiveTemplate, {"key": item, "value": 1}];
      setSelectedReceiveTemplate(updatedReceiveTemplate);
  } else {
      const updatedReceiveTemplate = selectedReceiveTemplate.filter((template) => template.key !== item);
      setSelectedReceiveTemplate(updatedReceiveTemplate);
  }
};

const handleSelectValueReceiveTemplate = (keyID, amount) => {
  const updatedReceiveTemplate = selectedReceiveTemplate.map(item => {
    if (item.key === keyID) {
      return { ...item, value: amount };
    }
    return item;
  });

  setSelectedReceiveTemplate(updatedReceiveTemplate);
};


  const handlerOpenList = () => {
    if (addedCollections.length) {
      setOpenList(false);
 
    } else {
      setOpenList(!openList);
    }
  };

  const handlerOpenListForSwap = () => {
    if (addedCollections.length) {
      setOpenListForSwap(false);

    } else {
      setOpenListForSwap(!openListForSwap);
      setSelectedSchemaForSwapOfferPool(null)
    }
  };

  const handlerOpenListForSchema = () => {
    if (addedCollections.length) {
      setOpenListForSchema(false);
    } else {
      setOpenListForSchema(!openListForSchema);
    }
  };

  const handlerOpenListForTemplate = () => {
    if (addedCollections.length) {
      setOpenListForTemplate(false);
    } else {
      setOpenListForTemplate(!openListForTemplate);
    }
  };

  const handlerOpenListForSwapTemplate = () => {
    if (addedCollections.length) {
      setOpenListForSwapTemplate(false);
    } else {
      setOpenListForSwapTemplate(!openListForSwapTemplate);
    }
  };

  const handlerOpenListForReceiveTemplate = () => {
    if (addedCollections.length) {
      setOpenListForReceiveTemplate(false);
    } else {
      setOpenListForReceiveTemplate(!openListForReceiveTemplate);
    }
  };

  function handleCurrentPrevStep() {
    setCurrentStep(currentStep - 1);
  }

  useEffect(() => {
    if (currentStep === 3) setCurrentStepName('Configuring Pool Parameters');

    if (currentStep === 4) setCurrentStepName('Finalizing Deposit');
  }, [currentStep]);

    const handleCreateOfferPool = async () => {
      await offerPoolInit(
        activeUser,
        selectedCollectionForSwapPool,
        selectedReceiveTemplate,
        selectedSwapTemplate,
        selectedSchemaForSwapOfferPool,
        verifiedAccounts,

        selectedNftToDeposit,
        selectedAmountSwapPool,

        additionalPriceOffrePool
        
      )
      .then(() => {
        toast.success('Pool created', toastOptions);

        navigate('/pool');
      })
      .catch((error) => {
        const errorMessage = error.message.replace(
          'assertion failure with message: ',
          ''
        );
        toast.error(errorMessage, toastOptions);
        console.log(error);
      });
    }


  return (
    <>
      <div className="create-pool-title">
        <h2>Create Pool</h2>
        <p>Provide liquidity to buy, sell, or trade NFTs on your behalf</p>
      </div>
      <div className="create-pool-stepper">
        <div className="create-pool-stepper_banner">
          <p>
            Step {currentStep}/4: {currentStepName}
          </p>
        </div>
        <div className="create-pool-stepper_wrapper">
          <FirstStep
            currentStep={currentStep}
            setSelectedOption={setSelectedOption}
            handleSelectOption={handleSelectOption}
          />

          <SecondStep
            currentStep={currentStep}
            selectedOption={selectedOption}
            selectedCollection={selectedCollection}
            selectedCollectionForSellPool={selectedCollectionForSellPool}
            show={show}
            openList={openList}
            setSearchCollectionValue={setSearchCollectionValue}
            handleSelectCollection={handleSelectCollection}
            handlerOpenList={handlerOpenList}
            handlerOpenListForSwap={handlerOpenListForSwap}
            handlerOpenListForSellSchema={handlerOpenListForSellSchema}
            handlerOpenListForSwapSchema={handlerOpenListForSwapSchema}
            selectedSchemaForSellPool={selectedSchemaForSellPool}
            openListForSellSchema={openListForSellSchema}
            currentSchemaForSell={currentSchemaForSell}
            handlerSelectedSchemaForSell={handlerSelectedSchemaForSell}
            handlerSelectedSchemaForSwapOfferPool={handlerSelectedSchemaForSwapOfferPool}
            handleSelectTemplate={handleSelectTemplate}
            handlerOpenListForTemplate={handlerOpenListForTemplate}
            selectedTemplate={selectedTemplate}
            openListForTemplate={openListForTemplate}
            currentTemplate={currentTemplate}
            selectedSchemaForSwapOfferPool={selectedSchemaForSwapOfferPool}
            setSelectedSchemaForSwapOfferPool={setSelectedSchemaForSwapOfferPool}

            setSelectedCollectionForSwapPool={setSelectedCollectionForSwapPool}
            selectedCollectionForSwapPool={selectedCollectionForSwapPool}
            handleSelectCollectionForSwapPool={handleSelectCollectionForSwapPool}

            openListForSwap={openListForSwap}
            openListForSwapSchema={openListForSwapSchema}
            setOpenListForSwapSchema={setOpenListForSwapSchema}

            currentSchemaForSwap={currentSchemaForSwap}

            handlerOpenListForSwapTemplate={handlerOpenListForSwapTemplate}
            handlerOpenListForReceiveTemplate={handlerOpenListForReceiveTemplate}
            setOpenListForSwapTemplate={setOpenListForSwapTemplate}
            setOpenListForReceiveTemplate={setOpenListForReceiveTemplate}
            openListForSwapTemplate={openListForSwapTemplate}
            handleSelectSwapTemplate={handleSelectSwapTemplate}
            currentSwapTemplate={currentSwapTemplate}
            selectedSwapTemplate={selectedSwapTemplate}
            setSelectedSwapTemplate={setSelectedSwapTemplate}
            setSelectedReceiveTemplate={setSelectedReceiveTemplate}

            selectedReceiveTemplate={selectedReceiveTemplate}
            openListForReceiveTemplate={openListForReceiveTemplate}
            currentReceiveTemplate={currentReceiveTemplate}
            handleSelectReceiveTemplate={handleSelectReceiveTemplate}
            handleSelectValueReceiveTemplate={handleSelectValueReceiveTemplate}

            setSelectedAmountSwapPool={setSelectedAmountSwapPool}
            selectedAmountSwapPool={selectedAmountSwapPool}

            setAdditionalPriceOffrePool={setAdditionalPriceOffrePool}

            openTokenListForBuy={openTokenListForBuy}
            setOpenTokenListForBuy={setOpenTokenListForBuy}

            openTokenListForSell={openTokenListForSell}
            setOpenTokenListForSell={setOpenTokenListForSell}

            selectedTokenToDeposit={selectedTokenToDeposit}
            handleSelectedTocenToDeposit={handleSelectedTocenToDeposit}

            openTokenListForTrade={openTokenListForTrade}
            setOpenTokenListForTrade={setOpenTokenListForTrade}
          />

          <ThirdStep
            currentStep={currentStep}
            selectedOption={selectedOption}
            setStartPrice={setStartPrice}
            startPrice={startPrice}
            setDelta={setDelta}
            delta={delta}
            selectedCollection={selectedCollection}
            setAmount={setAmount}
            amount={amount}
            setSliderValue={setSliderValue}
            slideValue={slideValue}
            price={price}
            priceExpon={priceExpon}
            setExponDelta={setExponDelta}
            exponDelta={exponDelta}
            priceTotal={priceTotal}
            priceExponTotal={priceExponTotal}
            setPriceExponTotal={setPriceExponTotal}
            setPriceTotal={setPriceTotal}
            feesAmountForTrade={feesAmountForTrade}
            setFeesAmountForTrade={setFeesAmountForTrade}
            setAmountToBuy={setAmountToBuy}
            amountToBuy={amountToBuy}
            setAmountToSell={setAmountToSell}
            amountToSell={amountToSell}
            slideValueToSell={slideValueToSell}
            setSliderValueToSell={setSliderValueToSell}
            setWillCost={setWillCost}
            setWillEarn={setWillEarn}
            willCostSum={willCostSum}
            willEarnSum={willEarnSum}
            visibility={visibility}
            setVisibility={setVisibility}
            verifiedAccounts={verifiedAccounts}
            setVerifiedAccounts={setVerifiedAccounts}

            selectedTokenToDeposit={selectedTokenToDeposit}
          />
          <FourthStep
            currentStep={currentStep}
            delta={delta}
            schema={selectedSchema}
            collection={selectedCollection}
            templates={selectedTemplate}
            startPrice={startPrice}
            waxTokenConfig={waxTokenConfig}
            exponDelta={exponDelta}
            selectedOption={selectedOption}
            selectedItemFromSell={selectedItemFromSell}
            price={price}
            priceTotal={priceTotal}
            priceExponTotal={priceExponTotal}
            totalPrice={totalPrice}
            selectedCollectionForSellPool={selectedCollectionForSellPool}
            selectedSchemaForSellPool={selectedSchemaForSellPool}
            myNFTs={myNFTs}
            totalTradeToCost={totalTradeToCost}
            totalTradeToEarn={totalTradeToEarn}
            feesTrade={feesAmountForTrade}
            selectedCollectionForSwapPool={selectedCollectionForSwapPool}
            selectedSchemaForSwapOfferPool={selectedSchemaForSwapOfferPool}
            selectedSwapTemplate={selectedSwapTemplate}
            selectedReceiveTemplate={selectedReceiveTemplate}
            visibility={visibility}
            verifiedAccounts={verifiedAccounts}
            setVisible={setVisible}
            setVisibleOffer={setVisibleOffer}
            selectedNftToDeposit={selectedNftToDeposit}
            selectedAmountSwapPool={selectedAmountSwapPool}

            selectedTokenToDeposit={selectedTokenToDeposit}
          />

          {currentStep !== 1 && (
            <div className="stepper-footer">
              {currentStep === 2 && (
                <OrangeBorderButton
                  width={136}
                  onClick={() => {
                    setSelectedCollection(null);
                    setCurrentStep(currentStep - 1);
                  }}
                >
                  <span className={'prev'}> &gt; </span> Prev step{' '}
                </OrangeBorderButton>
              )}
              {currentStep !== 2 && (
                <OrangeBorderButton
                  width={136}
                  onClick={() => handleCurrentPrevStep()}
                >
                  <span className={'prev'}> &gt; </span> Prev step{' '}
                </OrangeBorderButton>
              )}

              {currentStep === 2 && selectedOption === BUY && (
                <OrangeBorderButton
                  disabled={!selectedCollection}
                  className={selectedCollection ? '' : 'disabled'}
                  width={136}
                  onClick={() => setCurrentStep(currentStep + 1)}
                >
                  Next step <span className={'next'}> &gt; </span>
                </OrangeBorderButton>
              )}
              {currentStep === 2 && selectedOption === SELL && (
                <OrangeBorderButton
                  disabled={!selectedItemFromSell}
                  className={selectedItemFromSell ? '' : 'disabled'}
                  width={136}
                  onClick={() => setCurrentStep(currentStep + 1)}
                >
                  Next step <span className={'next'}> &gt; </span>
                </OrangeBorderButton>
              )}
              {currentStep === 2 && selectedOption === BOTH && (
                <OrangeBorderButton
                  disabled={!selectedCollection}
                  className={selectedCollection ? '' : 'disabled'}
                  width={136}
                  onClick={() => setCurrentStep(currentStep + 1)}
                >
                  Next step <span className={'next'}> &gt; </span>
                </OrangeBorderButton>
              )}
              {currentStep === 2 && selectedOption === OFFER && (
                <OrangeBorderButton
                  disabled={!selectedCollection && !selectedReceiveTemplate.length}
                  className={!selectedCollection && !selectedReceiveTemplate.length ? 'disabled' : ''}
                  width={136}
                  onClick={() => setCurrentStep(currentStep + 1)}
                >
                  Next step <span className={'next'}> &gt; </span>
                </OrangeBorderButton>
              )}
              {currentStep === 3 && selectedOption !== OFFER && (
                <OrangeBorderButton
                  disabled={startPrice <= 0}
                  className={startPrice > 0 ? '' : 'disabled'}
                  width={136}
                  onClick={() => setCurrentStep(currentStep + 1)}
                >
                  Next step <span className={'next'}> &gt; </span>
                </OrangeBorderButton>
              )}
              {currentStep === 3 && selectedOption === OFFER && (
                <OrangeBorderButton
                  // disabled={startPrice <= 0}
                  // className={startPrice > 0 ? '' : 'disabled'}
                  width={136}
                  onClick={() => setCurrentStep(currentStep + 1)}
                >
                  Next step <span className={'next'}> &gt; </span>
                </OrangeBorderButton>
              )}
              {currentStep !== 4 && currentStep !== 3 && currentStep !== 2 && (
                <OrangeBorderButton
                  disabled={!selectedCollection}
                  className={selectedCollection ? '' : 'disabled'}
                  width={136}
                  onClick={() => setCurrentStep(currentStep + 1)}
                >
                  Next step <span className={'next'}> &gt; </span>
                </OrangeBorderButton>
              )}
               {currentStep === 4 && selectedOption === OFFER && (
                <OrangeBorderButton
                  disabled={selectedNftToDeposit.length !== selectedAmountSwapPool}
                  className={selectedNftToDeposit.length !== selectedAmountSwapPool ? 'disabled' : ''}
                  width={136}
                  onClick={() => handleCreateOfferPool()}
                >
                  Done
                </OrangeBorderButton>
              )}
            </div>
          )}
        </div>
      </div>

      <RodalCreatePool
        visible={visible}
        hide={hide}
        handlerOpenList={handlerOpenList}
        handlerOpenListForSwap={handlerOpenListForSwap}
        selectedCollection={selectedCollection}
        openList={openList}
        setSearchCollectionValue={setSearchCollectionValue}
        allCollections={filteredAllCollections}
        handleSelectCollection={handleSelectCollection}
        handleSelectMyCollection={handleSelectMyCollection}
        handlerOpenListForSchema={handlerOpenListForSchema}
        selectedSchema={selectedSchema}
        openListForSchema={openListForSchema}
        currentSchema={currentSchema}
        handleSelectSchema={handleSelectSchema}
        selectedTemplate={selectedTemplate}
        openListForTemplate={openListForTemplate}
        currentTemplate={currentTemplate}
        handlerOpenListForTemplate={handlerOpenListForTemplate}
        handleSelectTemplate={handleSelectTemplate}
        handleAdd={handleAdd}
        addedCollections={addedCollections}
        deleteAddedCollection={deleteAddedCollection}
        selectedOption={selectedOption}
        myNFTs={myNFTs}
        setSelectedItemFromSell={setSelectedItemFromSell}
        selectedItemFromSell={selectedItemFromSell}
        allUserCollections={allUserCollections}
        selectedCollectionForSellPool={selectedCollectionForSellPool}
        setSelectedCollectionForSellPool={setSelectedCollectionForSellPool}
        selectedSchemaForSellPool={selectedSchemaForSellPool}
        setSelectedSchemaForSellPool={setSelectedSchemaForSellPool}
        setOpenListForSellSchema={setOpenListForSellSchema}
        openListForSellSchema={openListForSellSchema}
        currentSchemaForSell={currentSchemaForSell}
        setCurrentSchemaForSell={setCurrentSchemaForSell}
        handleSelectCollectionForSellPool={handleSelectCollectionForSellPool}
        handlerSelectedSchemaForSell={handlerSelectedSchemaForSell}
        handlerOpenListForSellSchema={handlerOpenListForSellSchema}
      />

      <RodalDepositOfferPool
         visible={visibleOffer}
         hide={hideOffer}
         myNFTs={myNFTs}
         handleSelectItem={handleSelectItemForDepositOfferPool}
         selectedNftToDeposit={selectedNftToDeposit}
         setSelectedNftToDeposit={setSelectedNftToDeposit}
         collection={selectedCollectionForSwapPool}
         schema={selectedSchemaForSwapOfferPool}
         templates={selectedSwapTemplate}
      />

    </>
  );
};

export default CreatePool;
