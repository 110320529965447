import React, { useState, useContext, useEffect } from 'react';
import { UALContext } from 'ual-reactjs-renderer';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import OrangeBorderButton from '../../components/OrangeBorderButton/OrangeBorderButton';
import RodalStakeUnstakePool from '../../components/Modal/RodalStakeUnstakePool/RodalStakeUnstakePool';

import { claimToken, getUserReward } from '../../services/wax.services';
import { toast } from 'react-toastify';
import { toastOptions } from '../../helpers/toast.helper';

import './Staking.scss';

const Staking = () => {
  const dispatch = useDispatch();
  const { activeUser } = useContext(UALContext);
  const alchBalance = useSelector((state) => state.alchBalance);
  const stakedTokens = useSelector((state) => state.stakedTokens);
  const rewadrTokens = useSelector((state) => state.userStakedReward);
  const tokenInfo = useSelector((state) => state.tokenInfo);

  const [visible, setVisible] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [shareProc, setShareProc] = useState(0);


  const openAncor = () => {
    window.open(
      'https://wax.alcor.exchange/trade/alch-alchemyswapy_wax-eosio.token',
      '_blank'
    );
  };

  const hide = () => {
    setVisible(false);
  };

  const handleStakeClick = () => {
    setModalContent('stake');
    setVisible(true);
  };

  const handleUnstakeClick = () => {
    setModalContent('unstake');
    setVisible(true);
  };

  const handleClaim = () => {
    claimToken({ activeUser })
      .then(() => {
        toast.success('Claimed', toastOptions);

        setTimeout(() => {
          if (activeUser) {
            getUserReward({ activeUser })
              .then((data) => {
                dispatch({ type: 'USER_STAKED_REWARD', payload: data });
              })
              .catch((error) => {
                console.log(error);
              });
          }
        }, 1500);
      })

      .catch((error) => {
        const errorMessage = error.message.replace(
          'assertion failure with message: ',
          ''
        );
        toast.error(errorMessage, toastOptions);
        console.log(error);
      });
  };

  useEffect(() => {
    const { staked_tokens, total_staked_tokens } = stakedTokens[0] || {};

    const sharePercentage =
      parseFloat(total_staked_tokens) > 0
        ? (parseFloat(staked_tokens) / parseFloat(total_staked_tokens)) * 100
        : 0;

    setShareProc(sharePercentage.toFixed(2));
  }, [stakedTokens]);

  return (
    <div className={'staking-section'}>
      <div className={'staking-section-title'}>
        <h2>ALCH Staking</h2>
      </div>
      {tokenInfo && (
        <div className={'staking-section-info'}>
          <p>
            Total supply: <span> {parseFloat(tokenInfo?.total)} ALCH</span>
          </p>
          <p>
            Current supply: <span>{tokenInfo?.current}</span>
          </p>
          <p>
            ALCH stakers receive: 50% of all fees made by buying NFTs on Alchemy
            Swap
          </p>
          <p>*Alchemy Swap fee = 1%</p>
          <p></p>
        </div>
      )}

      <div className={'staking-section-tables'}>
        <div className={'staking-section-table-1'}>
          <div className={'staking-section-table_row-1'}>
            {/* <div>
              <span>Claimable tokens:</span>
              <p>90.0600 ALCH</p>
            </div> */}
            <OrangeBorderButton width={164} onClick={handleClaim}>
              Claim
            </OrangeBorderButton>
          </div>
          <div className={'staking-section-table_row-2'}>
            <div>
              <span>Your ALCH Balance:</span>
              <p>{alchBalance}</p>
            </div>
            <div>
              <OrangeBorderButton width={164} onClick={handleStakeClick}>
                Stake
              </OrangeBorderButton>
              <OrangeBorderButton width={164} onClick={openAncor}>
                Trade via Alchor
              </OrangeBorderButton>
            </div>
          </div>
          <div className={'staking-section-table_row-3'}>
            <div>
              <span>Your staked ALCH:</span>
              <p>
                {stakedTokens.length
                  ? stakedTokens[0]?.staked_tokens
                  : '0 ALCH'}
              </p>
              <p>Share: {shareProc} %</p>
            </div>
            <OrangeBorderButton width={164} onClick={handleUnstakeClick}>
              Unstake
            </OrangeBorderButton>
          </div>
        </div>
        <div className={'staking-section-table-2'}>
          <div className={'staking-section-table-2_row-1'}>
            {rewadrTokens &&
              [rewadrTokens].map((item) => (
                <div className="reward-item">
                  <span>Your {String(item)?.split(' ')[1]} rewards:</span>
                  <p>{item}</p>
                </div>
              ))}
          </div>
          <div className={'staking-section-table-2_row-2'}>
            <span>Total ALCH staked:</span>
            <p>
              {stakedTokens.length
                ? stakedTokens[0]?.total_staked_tokens
                : '0 ALCH'}
            </p>
          </div>
        </div>
      </div>

      <RodalStakeUnstakePool
        hide={hide}
        visible={visible}
        content={modalContent}
      />
    </div>
  );
};

export default Staking;
