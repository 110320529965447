import React from 'react';
import Rodal from 'rodal';

import OrangeBorderButton from '../../OrangeBorderButton/OrangeBorderButton';

import '../RodalCreatePool/RodalCreatePool.scss';

import '../RodalCreatePool/rodal.css';
import { Checkbox } from '@mui/material';
import {
  depositNfts,
  getMyNFTs,
  getSellPoolsByCollections,
} from '../../../services/wax.services';
import { toast } from 'react-toastify';
import { toastOptions } from '../../../helpers/toast.helper';

function RodalDepositPool({
  visible,
  hide,
  myNFTs,
  setMyNFTs,
  handleSelectItem,
  selectedNftToDeposit,
  setSelectedNftToDeposit,
  id,
  user,
  name,
  setCurrentPool,
  setNFTonSale,
  setSelectedItemToWithdraw,
}) {
  const handleDepositNft = async () => {
    try {
      await depositNfts(user, selectedNftToDeposit, id, name);
      toast.success('Success', toastOptions);

      setTimeout(async () => {
        const data = await getSellPoolsByCollections();
        const pool = data.find((item) => item.pool_id === parseInt(id));

        setCurrentPool(pool);
        setNFTonSale(pool.pool_nfts);

        setSelectedItemToWithdraw([]);
      }, 1000);

      hide();

      setTimeout(async () => {
        const myNFT = await getMyNFTs(user?.accountName, name);

        setMyNFTs(myNFT);
        setSelectedNftToDeposit([]);
      }, 5000);
    } catch (error) {
      const errorMessage = error.message.replace(
        'assertion failure with message: ',
        ''
      );
      toast.error(errorMessage, toastOptions);
      console.log(error);
    }
  };

  return (
    <div className={'rodals'}>
      <Rodal
        className={'rodal-1'}
        visible={visible}
        onClose={hide}
        closeMaskOnClick={false}
      >
        <div className="title">
          <h2>Deposit NFT</h2>
          <p className={'sub-title'}>You can deposit NFT to your pool</p>
        </div>
        <div className="my-nft">
          <div className="my-nft_title">
            <h3>Selected NFTs: {selectedNftToDeposit.length}</h3>
            <OrangeBorderButton
              disabled={selectedNftToDeposit.length === 0}
              className={selectedNftToDeposit.length === 0 ? 'disabled' : ''}
              onClick={() => handleDepositNft()}
            >
              Confirm
            </OrangeBorderButton>
          </div>
          <div className="my-nft-list">
            {myNFTs &&
              myNFTs.map((item, index) => {
                return (
                  <div
                    className="my-nft-item"
                    key={index}
                    onClick={() => handleSelectItem(item.asset_id)}
                  >
                    <div className="checked">
                      <Checkbox
                        checked={selectedNftToDeposit.includes(item.asset_id)}
                        color="success"
                      />
                    </div>
                    <div className="image">
                      {item.data.img?.startsWith('Qm') ? (
                        <img
                          src={`https://atomichub-ipfs.com/ipfs/${item.data.img}`}
                          alt=""
                        />
                      ) : (
                        <img src={item.data.img} alt="" />
                      )}
                    </div>
                    <div className="collection-block">
                      <p>{item.collection.collection_name}</p>
                    </div>
                    <div className="asset-id">
                      <p>#{item.asset_id}</p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </Rodal>
    </div>
  );
}

export default RodalDepositPool;
