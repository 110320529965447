import { EOSIO_TOKEN, WUFFI_TOKEN } from "../constants/wax.constants";

import WaxIcon from '../images/wax_logo.webp';
import WuffiIcon from '../images/wuffi-token.png';

export const shortenText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength - 3) + '...';
  } else {
    return text;
  }
};

export const generatePointBackgroundColors = (amount, slideValue) => {
  return Array.from({ length: amount }, (_, i) =>
    i < slideValue ? '#E65634' : '#FFFFFF'
  );
};

export const getTokenIcon = (tokencontract) => {
  switch (tokencontract) {
    case EOSIO_TOKEN:
      return <img src={WaxIcon} alt="" />;
    case WUFFI_TOKEN:
      return <img src={WuffiIcon} alt="" />;
    default:
      return <img src={WaxIcon} alt="" />;
  }
}

export const getCurrentToken = (tokencontract) => {
  switch (tokencontract) {
    case EOSIO_TOKEN:
      return 'WAX'
    case WUFFI_TOKEN:
      return 'WUF'
    default:
      return 'WAX'
  }
};
