import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  getBuyPoolsByCollections,
  getCollectionByName,
  getOfferPoolsByCollections,
  getSellPoolsByCollections,
  getTradePoolsByCollections,
} from '../../services/wax.services';
import testBg from '../../images/background_bc.png';
import PoolItem from '../../components/PoolItem/PoolItem';
import './CollectionPage.scss';

const CollectionPage = () => {
  const location = useLocation();
  const [name, setName] = useState('');
  const [tabActive, setTabActive] = useState(1);
  const [sellPools, setSellPools] = useState([]);
  const [buyPools, setBuyPools] = useState([]);
  const [tradePools, setTradePools] = useState([]);
  const [offerPools, setOfferPools] = useState([]);
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  const [currentCollection, setCurrentCollection] = useState([]);
  const [showOnlyNFT, setShowOnlyNFT] = useState(true);
  const [showOnlyToken, setShowOnlyToken] = useState(true);
  const [showOnlyTrade, setShowOnlyTrade] = useState(true);
  const [showOnlyOffer, setShowOnlyOffer] = useState(true);
  const [minFloorPrice, setMinFloorPrice] = useState('0 WAX');

  useEffect(() => {
    const currentPath = location.pathname;
    const collectionName = currentPath.split('/')[2];
    setName(collectionName);
  }, [location]);

  useEffect(() => {
    if (name) {
      getCollectionByName(name)
        .then((data) => setCurrentCollection(data))
        .catch((error) => console.log(error));
    }
  }, [name]);

  useEffect(() => {
    const fetchPools = async () => {
      try {
        const [sellData, buyData, tradeData, offerData] = await Promise.all([
          getSellPoolsByCollections(),
          getBuyPoolsByCollections(),
          getTradePoolsByCollections(),
          getOfferPoolsByCollections(),
        ]);

        const filteredSellPools = sellData.filter((pool) => pool.pool_nfts.length > 0);
        const filteredBuyPools = buyData.filter((pool) => parseFloat(pool.pool_asset.split(' ')[0]) > parseFloat(pool.floor_price.split(' ')[0]));
        const filteredTradePools = tradeData.filter((pool) => pool.pool_nfts.length > 0);
        const filteredOfferPools = offerData.filter((pool) => pool.pool_nfts.length > 0);

        setSellPools(filteredSellPools);
        setBuyPools(filteredBuyPools);
        setTradePools(filteredTradePools);
        setOfferPools(filteredOfferPools);

        const allPools = [...filteredSellPools, ...filteredBuyPools, ...filteredTradePools]
          .filter((pool) => pool.NFTs_collection?.collection_name === name)

          // Find minimum floor price
        const minPrice = Math.min(...allPools.map(pool => parseFloat(pool.floor_price.split(' ')[0])));
        if (minPrice === Infinity) {
          setMinFloorPrice('0 WAX');
        }else{
          setMinFloorPrice(minPrice.toString() + ' WAX');
        }
       

      } catch (error) {
        console.log(error);
      }
    };

    fetchPools();
  }, [name]);

  const handleTabClick = (tabIndex) => {
    setTabActive(tabIndex);
  };

  const handleMinPriceChange = (event) => {
    setMinPrice(event.target.value);
  };

  const handleMaxPriceChange = (event) => {
    setMaxPrice(event.target.value);
  };

  const filteredSellPools = sellPools.filter((pool) => {
    const floorPrice = parseFloat(pool.floor_price.split(' ')[0]);
    return (
      pool.NFTs_collection?.collection_name === name &&
      pool.floor_price.includes('WAX') &&
      (minPrice === '' || floorPrice >= parseFloat(minPrice)) &&
      (maxPrice === '' || floorPrice <= parseFloat(maxPrice))
    );
  });

  const filteredBuyPools = buyPools.filter((pool) => {
    const floorPrice = parseFloat(pool.floor_price.split(' ')[0]);
    return (
      pool.NFTs_collection?.collection_name === name &&
      pool.floor_price.includes('WAX') &&
      (minPrice === '' || floorPrice >= parseFloat(minPrice)) &&
      (maxPrice === '' || floorPrice <= parseFloat(maxPrice))
    );
  });

  const filteredTradePools = tradePools.filter((pool) => {
    const floorPrice = parseFloat(pool.floor_price.split(' ')[0]);
    return (
      pool.NFTs_collection?.collection_name === name &&
      pool.floor_price.includes('WAX') &&
      (minPrice === '' || floorPrice >= parseFloat(minPrice)) &&
      (maxPrice === '' || floorPrice <= parseFloat(maxPrice))
    );
  });

  const filteredOfferPools = offerPools.filter((pool) => pool.NFTs_collection?.collection_name === name && pool.owner_receiving_amount > 0);

  const handlerSelectedPool = (item) => {
    console.log(item);
  };

  return (
    <>
      <div
        className={'collection'}
        style={{ backgroundImage: `url(${testBg})` }}
      >
        <div className="collection-header">
          <div className="collection-header_name">
            <div className="logo">
              {currentCollection?.data?.img &&
              currentCollection?.data.img?.startsWith('Qm') ? (
                <img
                  src={`https://atomichub-ipfs.com/ipfs/${currentCollection.data?.img}`}
                  alt=""
                />
              ) : (
                <img src={currentCollection.data?.img} alt="" />
              )}
            </div>
            <p>{name}</p>
          </div>
          <div className="collection-header_about">
            <div className="collection-header_about_wrapper">
              <div className="floor">
                <h6>Floor price</h6>
                <p>{minFloorPrice}</p>
              </div>
              <div className="volume">
                <h6>Sales</h6>
                <p>{currentCollection?.sales}</p>
              </div>
              <div className="total">
                <h6>Total Volume</h6>
                <p>{Math.trunc((currentCollection?.volume / Math.pow(10, 8)))} WAX</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="collection-content">
        <div className="collection-content_header">
          <p
            className={tabActive === 1 ? 'active' : ''}
            onClick={() => handleTabClick(1)}
          >
            Pools (
            {filteredSellPools.length +
              filteredBuyPools.length +
              filteredTradePools.length +
              filteredOfferPools.length}
            )
          </p>
          <p
            className={tabActive === 2 ? 'active' : ''}
            onClick={() => handleTabClick(2)}
          >
            Activity
          </p>
        </div>
        {tabActive === 1 && (
          <div className={'collection-content_container'}>
            <div className="collection-content_sidebar">
              <div className="min-max-price">
                <p>Price</p>
                <div className="inputs">
                  <input
                    type="text"
                    className="min"
                    placeholder={'Min price (WAX)'}
                    onChange={(e) => handleMinPriceChange(e)}
                  />
                  <input
                    type="text"
                    className="max"
                    placeholder={'Max price (WAX)'}
                    onChange={(e) => handleMaxPriceChange(e)}
                  />
                </div>
              </div>
              <div className="pool-type">
                <p className={'pool-type_p'}>Pool type</p>
                <div className="pool-type_item">
                  <p>NFT ({filteredSellPools.length})</p>
                  <input
                    type="checkbox"
                    className={'custom-checkbox'}
                    onChange={() => {
                      setShowOnlyToken(!showOnlyToken);
                      setShowOnlyTrade(!showOnlyTrade);
                      setShowOnlyOffer(!showOnlyOffer);
                    }}
                  />
                </div>
                <div className="pool-type_item">
                  <p>Token ({filteredBuyPools.length})</p>
                  <input
                    type="checkbox"
                    className={'custom-checkbox'}
                    onChange={() => {
                      setShowOnlyNFT(!showOnlyNFT);
                      setShowOnlyTrade(!showOnlyTrade);
                      setShowOnlyOffer(!showOnlyOffer);
                    }}
                  />
                </div>
                <div className="pool-type_item">
                  <p>Trade ({filteredTradePools.length})</p>
                  <input
                    type="checkbox"
                    className={'custom-checkbox'}
                    onChange={() => {
                      setShowOnlyToken(!showOnlyToken);
                      setShowOnlyNFT(!showOnlyNFT);
                      setShowOnlyOffer(!showOnlyOffer);
                    }}
                  />
                </div>
                <div className="pool-type_item">
                  <p>Offer ({filteredOfferPools.length})</p>
                  <input
                    type="checkbox"
                    className={'custom-checkbox'}
                    onChange={() => {
                      setShowOnlyToken(!showOnlyToken);
                      setShowOnlyNFT(!showOnlyNFT);
                      setShowOnlyTrade(!showOnlyTrade);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="collection-content_list">
              <PoolItem
                filteredSellPools={showOnlyNFT ? filteredSellPools : []}
                filteredBuyPools={showOnlyToken ? filteredBuyPools : []}
                filteredTradePools={showOnlyTrade ? filteredTradePools : []}
                filteredOfferPools={showOnlyOffer ? filteredOfferPools : []}
                handlerSelectedPool={handlerSelectedPool}
                collectionsName={name}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CollectionPage;
