import React from 'react';
import Select from 'react-select';


import WAXToken from '../../images/wax_logo.png';
import WUFToken from '../../images/wuffi-token.png';

import './FilterSideBar.scss';
import { EOSIO_TOKEN, WUFFI_TOKEN } from '../../constants/wax.constants';


const tokens = [
  { value: null, label: 'All', image: null },
  { value: EOSIO_TOKEN, label: 'WAX', image: WAXToken },
  { value: WUFFI_TOKEN, label: 'WUF', image: WUFToken }
];


const FillterSideBar = ({
  sellPools,
  buyPools,
  selectedFilterCollection,
  setSelectedFilterCollection,
  setSelectedFilterSchema,
  setSelectedPoolType,
  selectedPoolType,
  setSelectedToken,
}) => {
  const handleCheckboxClick = (type) => {
    if (type === selectedPoolType) {
      setSelectedPoolType('');
    } else {
      setSelectedPoolType(type);
    }
  };

  const handleSelectSchema = (schema) => {
    setSelectedFilterSchema(schema);

    sellPools.concat(buyPools).forEach((item) => {
      if (item.schemas.includes(...schema)) {
        setSelectedFilterCollection(item.NFTs_collection.collection_name);
      }
    });
  };

  function handleSelectedFilterCollection(event) {
    setSelectedFilterCollection(event.NFTs_collection.collection_name);
    setSelectedFilterSchema([]);
  }


  function handleSelectedFilterToken(event) {
    setSelectedToken(event.value);
  }

  const uniqueCollections = Array.from(new Set([...[{NFTs_collection: {collection_name: 'All'}}],...sellPools, ...buyPools].map(token => token.NFTs_collection.collection_name)))
    .map(collectionName => {
        return {
            NFTs_collection: {
                collection_name: collectionName
            }
        };
    });

  return (
    <div className="side-bar">
      <div className="token">
        <p>Token</p>
        <div className="selected-field">
          <Select
            className='token-select'
            classNamePrefix={'token'}
            onChange={(event) => handleSelectedFilterToken(event)}
            defaultValue={tokens[0]}
            options={tokens}
            formatOptionLabel={token => (
              <div className="tokens-option">
                {token.image && <img src={token.image} alt={`country-${token.value}`} />}
                <span>{token.label}</span>
              </div>
            )}
          />
        </div>
      </div>
      <div className="collections">
        <p>Collections</p>
        <div className="selected-field">
          <Select
            className='token-select'
            classNamePrefix={'token'}
            onChange={(event) => handleSelectedFilterCollection(event)}
            defaultValue={tokens[0]}
            options={uniqueCollections}
            formatOptionLabel={token => (
              <div className="tokens-option">
                  <span>{token?.NFTs_collection?.collection_name}</span>
              </div>
            )}
          />
        </div>
      </div>
      <div className="schemas">
        <p>Schemas</p>
        <div className="schemas-list">
          {sellPools
            .concat(buyPools)
            .filter((item) => {
              if (selectedFilterCollection === 'all') {
                return true;
              }
              return (
                item.NFTs_collection.collection_name ===
                selectedFilterCollection
              );
            })
            .reduce((uniqueSchemas, item) => {
              if (!uniqueSchemas.includes(item.schemas)) {
                uniqueSchemas.push(item.schemas);
              }
              return uniqueSchemas;
            }, [])
            .map((schema, index) => (
              <p key={index} onClick={() => handleSelectSchema(schema)}>
                {schema.join(', ')}
              </p>
            ))}
        </div>
      </div>
      <div className="tool-type">
        <p>Pool type</p>
        <div className="tool-type-block">
          <p>NFT</p>
          <input
            type="checkbox"
            checked={selectedPoolType === 'nft'}
            onChange={() => handleCheckboxClick('nft')}
          />
        </div>
        <div className="tool-type-block">
          <p>Token</p>
          <input
            type="checkbox"
            checked={selectedPoolType === 'token'}
            onChange={() => handleCheckboxClick('token')}
          />
        </div>
        <div className="tool-type-block">
          <p>Trade</p>
          <input
            type="checkbox"
            checked={selectedPoolType === 'trade'}
            onChange={() => handleCheckboxClick('trade')}
          />
        </div>
        <div className="tool-type-block">
          <p>Offer</p>
          <input
            type="checkbox"
            checked={selectedPoolType === 'offer'}
            onChange={() => handleCheckboxClick('offer')}
          />
        </div>
      </div>
    </div>
  );
};

export default FillterSideBar;
